import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ChatIcon from "@mui/icons-material/Chat";
import ContactUsForm from "./ContactUs";
import ContactEmailTemplate from "./ContactEmailTemplate";
const initialState = {
  firstName: "",
  email: "",
  phone: "",
  note: "",
};
const AdviceCard: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialState);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: false }));
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let valid = true;
    let errorsTemp = { firstName: "", email: "", phone: "", note: "" };

    if (!formData.firstName.trim()) {
      errorsTemp.firstName = "Name is required";
      valid = false;
    }

    if (!formData.email.trim()) {
      errorsTemp.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errorsTemp.email = "Email is not valid";
      valid = false;
    }

    if (!formData.phone.trim()) {
      errorsTemp.phone = "Phone number is required";
      valid = false;
    }
    const phoneRegex = /^\+\d{1,3}\s?\d{10}$/; // Regex for phone number with country code
    if (!phoneRegex.test(formData.phone)) {
      errorsTemp.phone =
        "Phone number must be in the format +[country code] [phone number]";
      valid = false;
    } else {
      const phoneRegex = /^\+\d{1,3}\s?\d{10}$/; // Regex for phone number with country code
      if (!phoneRegex.test(formData.phone)) {
        errorsTemp.phone =
          "Phone number must be in the format +[country code] [phone number]";
        valid = false;
      } else {
        const phoneNumber = formData.phone.replace(/^\+\d{1,2}\s?/, ""); // Remove country code
        console.log(phoneNumber);
        if (phoneNumber.length !== 10) {
          errorsTemp.phone = "Phone number must be 10 digits";
          valid = false;
        }
      }
    }

    if (!formData.note.trim()) {
      errorsTemp.note = "Description is required";
      valid = false;
    }

    setErrors(errorsTemp);
    return valid;
  };

  const handleSubmit = async () => {
    if (validate()) {
      const emailContent = ReactDOMServer.renderToString(
        <ContactEmailTemplate companyName="BidzBay" formData={formData} />
      );

      try {
        await sendEmail(
          formData.email,
          "Thank you for contacting us",
          emailContent
        );
        alert("We have received your message and will respond promptly")
      } catch (error) {
        console.error("Error sending email:", error);
      }
      setFormData(initialState);
      handleClose();
      console.log("Form data:", formData);
    }
  };

  const sendEmail = async (
    to: string,
    subject: string,
    htmlContent: string
  ) => {
    return await fetch(`${process.env.REACT_APP_API_BASE_URL_USER}mail/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: `${to}, ${process.env.REACT_APP_CONTACT_EMAIL}`,
        subject: "Thank you for contacting BidzBay",
        template: htmlContent,
      }),
    });
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          borderRadius:"0px",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "stretch" : "center",
          //m: 2,
          
          p: 1,
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.tertiary,
        }}
      >
        <CardContent
          sx={{
            flex: "1 0 auto",
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          <Typography component="div" variant="h5">
            Looking for Advice?
          </Typography>
          <Typography variant="subtitle1" component="div">
            Get personalized advice from our experts.
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: isSmallScreen ? "center" : "flex-start",
            width: isSmallScreen ? "100%" : "auto",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,

              width: isSmallScreen ? "80%" : "auto",
            }}
            startIcon={<ChatIcon />}
            onClick={handleClickOpen}
          >
            Contact Us
          </Button>
        </CardActions>
      </Card>

      <ContactUsForm
        open={open}
        handleClose={handleClose}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default AdviceCard;
