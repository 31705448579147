import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import GradingIcon from "@mui/icons-material/Grading";
import { keyframes } from "@emotion/react";
import ConfirmDialog from "./ConfirmDialog";
import { ProductType } from "./product.type";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import BidDialog from "./Bid/BidDialog";
import { useDispatch } from "react-redux";
//import { useGoogleLogin } from "../../components/auth/GoogleLoginContext";
import { setLoginModelVisibility } from "../../store/slices/auth.slice";
import {
  useAcceptBid,
  useDeleteBid,
  usePlaceBid,
  useUpdateBid,
} from "../services/bid.service";
import BiddingTimer from "./Bid/BidTimer";
import { COUNTRY_CURRENCY } from "../utils/constants/constants";
import CurrencyPrice, { convertPrice } from "../common/PriceWithCurrency";
import ViewBidModal from "./Bid/ViewBidDialog";
import { isDateExpired } from "../utils/helpers";
import { matchIsValidTel } from "mui-tel-input";

interface User {
  id: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_exclusive: boolean;
}

export interface Bid {
  productId: string;
  currency: string;
  amount: number;
  buyer: User;
  isAccepted: boolean;
  uuid: string;
  phone_number: string;
}

interface BidCardProps {
  product: ProductType;
  bids: Bid[];
}

const animate = keyframes`
  50% {
    box-shadow: 1px 15px 20px;
  }
`;

const BidCard: React.FC<BidCardProps> = React.memo(({ bids, product }) => {
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBid, setSelectedBid] = useState<Bid | null>(null);
  const [myBid, setMyBid] = useState<Bid | null>(null);
  const [bidClosed, setBidClosed] = useState(false);
  const [number, setNumberChange] = useState<string>();
  const { user } = useSelector(
    (state: RootState) => state.auth.currentUserDetails
  );

  const [openBidDialog, setOpenBidDialog] = useState(false);

  const { mutate: acceptBid } = useAcceptBid(dispatch);
  const { mutate: deleteBid } = useDeleteBid(dispatch);

  useEffect(() => {
    setNumberChange(user?.phone_number);
  }, [user?.phone_number]);

  useEffect(() => {
    const myBid = bids?.find((bid) => bid.buyer.id === user?.uuid) || null;
    setMyBid(myBid);
    // const isBidClosed = bids?.find((bid) => bid.isAccepted);
    setBidClosed(product?.state === "SOLD");
  }, [bids, product?.state, user?.uuid]);

  const handleAcceptClick = (bid: Bid) => {
    setSelectedBid(bid);
    setDialogOpen(true);
  };

  const handleBidDelete = (bid: Bid) => {
    deleteBid({ bidId: bid.uuid });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    if (selectedBid) {
      acceptBid({
        bidId: selectedBid.uuid,
        productId: product.uuid,
        consent,
      });
      setViewModal(false);
    }
    setDialogOpen(false);
  };
  const [viewModal, setViewModal] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setViewModal(true);
  };
  const handleClose = () => {
    setViewModal(false);
  };

  const handlePlaceBid = () => {
    if (user && user?.uuid) {
      setOpenBidDialog(true);
    } else {
      dispatch(setLoginModelVisibility({ showLogin: true }));
    }
  };

  const location = useSelector((state: RootState) => state.location.location);
  const { rates } = useSelector((state: RootState) => state.currency);
  const [consent, setConsent] = useState(true);

  const [bidAmount, setBidAmount] = React.useState(0);
  const [currency, setCurrency] = React.useState(
    localStorage.getItem("currency") || "USD"
  );
  const [error, setError] = useState<any>({
    bidAmount: "",
    phone_number: "",
  });
  const MINIMUM_CURRENCY = product?.currency || "USD";
  const MINIMUM_AMOUNT = Number(
    convertPrice(product.price, MINIMUM_CURRENCY, location, rates).toFixed(0)
  );

  const { mutate: placeBid } = usePlaceBid(dispatch);
  const { mutate: updateBid } = useUpdateBid(dispatch);

  React.useEffect(() => {
    if (location) {
      setCurrency(
        COUNTRY_CURRENCY[
          (location?.country as keyof typeof COUNTRY_CURRENCY) || "USD"
        ]
      );
    } else {
      setCurrency("USD");
    }
  }, [location]);

  const handleAmountChange = (event: { target: { value: any } }) => {
    setError("");
    if (event.target.value < 0) {
      return (event.target.value = "");
    }
    setError({
      bidAmount: "",
    });
    setBidAmount(Number(event.target.value));
  };

  const handleNumberChange = (value: string) => {
    setError({
      phone_number: "",
    });

    setNumberChange(value);
  };

  const handleCurrencyChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCurrency(event.target?.value);
  };

  const handleSubmit = () => {
    if (bidAmount < MINIMUM_AMOUNT) {
      setError({ bidAmount: `The minimum bid amount is ₹${MINIMUM_AMOUNT}` });
    } else if (!number) {
      setError({ phone_number: `Phone number is required` });
    } else {
      const valid = matchIsValidTel(number);
      if (!valid) {
        setError({ phone_number: `Phone number is not valid` });
      } else {
        setError({
          bidAmount: "",
          phone_number: "",
        });
       myBid && myBid.uuid
          ? updateBid({
              bidId: myBid?.uuid || "",
              amount: bidAmount,
              currency,
              consent,
              phone_number: number,
            })
          : placeBid({
              productId: product.uuid || "",
              amount: bidAmount,
              currency: currency || "USD",
              consent,
              phone_number: number,
            });
        setOpenBidDialog(false);
      }
    }
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          background: "white",
          marginTop: "1.5rem",
          boxShadow: "0.8px 1px 3px 1px grey",
        }}
      >
        <CardContent>
          {product?.bidEndDate && (
            <BiddingTimer
              bidEndDate={new Date(product.bidEndDate)}
              isBidClosed={bidClosed}
            />
          )}

          <Grid container sx={{ marginTop: "2vh" }}>
            <Grid item xs={6} sm={7} md={7}>
              <Typography
                gutterBottom
                variant="h6"
                sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Bid End Date:{" "}
              </Typography>

              <Typography
                gutterBottom
                variant="h6"
                sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Starting Bidding Amount:{" "}
              </Typography>

              {bids && bids.length > 0 && (
                <>
                  <Typography
                    gutterBottom
                    variant="h6"
                    sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    Top Bidding Amount:{" "}
                  </Typography>
                  {myBid && (
                    <Typography
                      gutterBottom
                      variant="h6"
                      sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                    >
                      My Amount:{" "}
                    </Typography>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={6} sm={5} md={5}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontSize: "0.8rem", color: "gray" }}
              >
                {product?.bidEndDate
                  ? new Date(product.bidEndDate).toLocaleDateString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })
                  : "NA"}
              </Typography>

              <Typography
                gutterBottom
                display="inline-flex"
                alignItems="center"
                sx={{ fontSize: "0.8rem", color: "grey" }}
              >
                <CurrencyPrice
                  price={product?.price}
                  currency={product?.currency || currency}
                />
              </Typography>

              {bids && bids.length > 0 && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontSize: "0.8rem",
                      color: "Black",
                      fontWeight: "600",
                    }}
                  >
                    <CurrencyPrice
                      price={bids[0]?.amount}
                      currency={bids[0]?.currency || currency}
                    />
                  </Typography>
                  {myBid && (
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontSize: "0.8rem", color: "gray" }}
                    >
                      <CurrencyPrice
                        price={myBid?.amount}
                        currency={myBid.currency || currency}
                      />
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxWidth: "100%",
            }}
          >
            {product?.seller?.id !== user?.uuid &&
              !bidClosed &&
              !isDateExpired(product?.bidEndDate) && (
                <Button
                  fullWidth={!bids || bids?.length === 0}
                  startIcon={<GavelIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handlePlaceBid}
                  sx={{
                    animation: `${animate} 2s infinite`,
                    marginTop: "2vh",
                    flexGrow: 1,
                    maxWidth: bids && bids.length > 0 ? "48%" : "100%",
                  }}
                  autoFocus
                >
                  Place Bid
                </Button>
              )}
            {bids &&
              bids.length > 0 &&
              !bidClosed &&
              !isDateExpired(product?.bidEndDate) && (
                <Button
                  startIcon={<GradingIcon />}
                  variant="outlined"
                  onClick={handleClick}
                  sx={{
                    marginLeft: "5px",
                    fontWeight: "bold",
                    marginTop: "2vh",
                    flexGrow: 1, // Allows this button to grow as well
                    maxWidth: "48%", // Max width to ensure alignment with the other button
                  }}
                >
                  View Bids ({bids.length})
                </Button>
              )}
          </Box>
        </CardContent>
      </Card>
      <ConfirmDialog
        open={dialogOpen}
        title="Confirm Accept Bid"
        description={`Are you sure you want to accept the bid from ${
          selectedBid?.buyer?.first_name
        } ${
          selectedBid?.buyer?.last_name ? selectedBid?.buyer?.last_name : ""
        } for ${currency} ${selectedBid?.amount}?`}
        checked={consent}
        setConsent={setConsent}
        showConfirm={true}
        onCancel={handleDialogClose}
        onConfirm={handleConfirm}
      />
      <BidDialog
        open={openBidDialog}
        myBid={myBid}
        product={product}
        onClose={() => setOpenBidDialog(false)}
        topBid={bids && bids[0]?.amount ? bids[0]?.amount : 0}
        topBidCurrent={bids && bids[0]?.currency}
        currency={currency}
        error={error}
        phone_number={
          myBid && myBid?.phone_number ? myBid?.phone_number : number || ""
        }
        handleAmountChange={handleAmountChange}
        setCurrency={handleCurrencyChange}
        checked={consent}
        setConsent={setConsent}
        handleSubmit={handleSubmit}
        handleNumberChange={handleNumberChange}
      />

      {viewModal && bids && bids.length > 0 && !bidClosed && (
        <ViewBidModal
          bids={bids}
          product={product}
          currency={currency}
          user={user}
          open={viewModal}
          handleAccepterChange={handleAcceptClick}
          handleBidDelete={handleBidDelete}
          handleClose={handleClose}
        />
      )}
    </>
  );
});

export default BidCard;
