import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import GeosuggestDropdown from "../GeosuggestDropdown";

const LocationPrompt: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [showGeosuggest, setShowGeosuggest] = useState(false);

  const checkLocation = () => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      () => {
        handleClose();
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setOpen(true);
          setShowGeosuggest(true);
        }
      }
    );
  };

  useEffect(() => {
    checkLocation();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEnableLocation = () => {
    const userAgent = navigator.userAgent;
    let message = "Please enable location services in your browser settings.";
    if (/Chrome/i.test(userAgent)) {
      message =
        'To enable location services:\n1. Click on the padlock icon in the address bar.\n2. Find the "Location" option and select "Allow".';
    } else if (/Firefox/i.test(userAgent)) {
      message =
        'To enable location services:\n1. Click on the shield icon in the address bar.\n2. Select "Permissions" and enable "Location".';
    } else if (/Safari/i.test(userAgent)) {
      message =
        'To enable location services:\n1. Go to "Safari" > "Preferences" > "Websites".\n2. Find "Location" and select "Allow" for this site.';
    }

    alert(message);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Enable Location</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          To provide a better experience, choose location manually.
        </Typography>
        {showGeosuggest && (
          <Box mt={2} mb={2}>
            <GeosuggestDropdown showInput={true} setShowInput={() => {}} />
          </Box>
        )}
        <Typography variant="body2" color="textSecondary">
          Alternatively, you can enable location services from your browser.{" "}
          <Button onClick={handleEnableLocation} color="primary">
            Enable Location
          </Button>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationPrompt;
