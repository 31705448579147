import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Container, Typography, Box, Divider,Grid,useMediaQuery, TextField,
    Button, } from "@mui/material";
import conImg from "assets/contactimg.png"
import Breadcrumb from "../BreadCrumbs";
import { useTheme } from "@mui/material/styles";
import ChatIcon from "@mui/icons-material/Chat";
import ContactUsForm from "../dashboard/ContactUs";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Toaster from "../toaster/Toaster";
import ContactEmailTemplate from "../dashboard/ContactEmailTemplate";
const initialState = {
  firstName: "",
  email: "",
  phone: "",
  note: "",
};

const ContcatUs: React.FC = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialState);


  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: false }));
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let valid = true;
    let errorsTemp = { firstName: "", email: "", phone: "", note: "" };

    if (!formData.firstName.trim()) {
      errorsTemp.firstName = "Name is required";
      valid = false;
    }

    if (!formData.email.trim()) {
      errorsTemp.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errorsTemp.email = "Email is not valid";
      valid = false;
    }

    if (!formData.phone.trim()) {
      errorsTemp.phone = "Phone number is required";
      valid = false;
    }
    const phoneRegex = /^\+\d{1,3}\s?\d{10}$/; // Regex for phone number with country code
    if (!phoneRegex.test(formData.phone)) {
      errorsTemp.phone =
        "Phone number must be in the format +[country code] [phone number]";
      valid = false;
    } else {
      const phoneRegex = /^\+\d{1,3}\s?\d{10}$/; // Regex for phone number with country code
      if (!phoneRegex.test(formData.phone)) {
        errorsTemp.phone =
          "Phone number must be in the format +[country code] [phone number]";
        valid = false;
      } else {
        const phoneNumber = formData.phone.replace(/^\+\d{1,2}\s?/, ""); // Remove country code
        console.log(phoneNumber);
        if (phoneNumber.length !== 10) {
          errorsTemp.phone = "Phone number must be 10 digits";
          valid = false;
        }
      }
    }

    if (!formData.note.trim()) {
      errorsTemp.note = "Description is required";
      valid = false;
    }

    setErrors(errorsTemp);
    return valid;
  };

  const handleSubmit = async () => {
    if (validate()) {
      const emailContent = ReactDOMServer.renderToString(
        <ContactEmailTemplate companyName="BidzBay" formData={formData} />
    
      );
     
      try {
        await sendEmail(
          formData.email,
          
          "Thank you for contacting us",
          emailContent
        );
        
        alert("We have received your message and will respond promptly")
    
      } catch (error) {
        console.error("Error sending email:", error);
      }
      setFormData(initialState);
      handleClose();
      console.log("Form data:", formData);
    }
  };

  const sendEmail = async (
    to: string,
    subject: string,
    htmlContent: string
  ) => {
    return await fetch(`${process.env.REACT_APP_API_BASE_URL_USER}mail/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: `${to}, ${process.env.REACT_APP_CONTACT_EMAIL}`,
        subject: "Thank you for contacting BidzBay",
        template: htmlContent,
      }),
    });
  };

  const getProduct = () => {
    //const encodedMessage = encodeURIComponent(`${window.location.hostname}/`);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP}&text=Hey, I need some help on your website https://bidzbay.com`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <Container maxWidth="md">
      
      <Box position="static" sx={{ padding: theme.spacing(isSmallScreen?2:5) }}></Box>
      <Breadcrumb />
      <Box my={4}>
        <Grid sx={{
           backgroundColor:"#b4f0ee",
           textAlign:"center",
           p:2,
          borderRadius:"10px",
          mb:4
           
        }}>
          <img src={conImg} width={40}/>
        <Typography variant="h1" gutterBottom 
        sx={{fontSize: isSmallScreen? "1.2rem":"2rem",
          textAlign:"center",
          color:"black"
        }}
        >
         Contact Us
        </Typography>
        </Grid>
        <Box component="form" sx={{ mt: 2 }}>
          <TextField
            margin="dense"
            name="firstName"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Phone No."
            type="tel"
            fullWidth
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={
              errors.phone ||
              "Please enter a phone number with country code like +919988776655"
            }
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="note"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={formData.note}
            onChange={handleChange}
            error={!!errors.note}
            helperText={errors.note}
            sx={{ mb: 2 }}
          />
        </Box>
        <Button onClick={handleSubmit} color="primary" variant="contained" sx={{mb:2}}>
          Submit
        </Button>
        <Divider>OR</Divider>

        <Typography fontWeight={600} textAlign={"center"} mt={2} mb={2}> Contact Us Via 
        <Button variant="contained" size="small" onClick={() => getProduct()} sx={{
          ml:2
        }}>
            <WhatsAppIcon />
          </Button>
        </Typography>
        <Divider textAlign="left">Company Details</Divider>
        <Box mt={2} >
          <Typography variant="body2" fontSize={"1rem"}> Company Name: Operated and owned by Gulf Land Commercial</Typography>
          <Typography variant="body2" fontSize={"1rem"}> Address: Floor 7, Building 241, Al Khuwair, Muscat, Oman</Typography>
          <Typography variant="body2" fontSize={"1rem"}> Email id: support@bidzbay.com</Typography>
        </Box>
    
        </Box>
    </Container>
  );
};

export default ContcatUs;
