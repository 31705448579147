import { useMutation, useQuery, useQueryClient } from "react-query";
import fetchWrapper, {
  RequestOptions,
} from "../../components/utils/fetchWrapper";
import { Dispatch } from "redux";
import { showToast } from "../../store/slices/toastSlice";
import { TOAST_MESSAGES } from "../../components/utils/constants/toastMessages";
// export const bidService = {
//   placeBid,
//   getAllBids,
// };

const getAllBids = async (productId: string) => {
  return fetchWrapper(`bids/product/${productId}`);
};

async function placeBid(
  productId: string,
  amount: number,
  currency: string,
  consent: boolean = false,
  phone_number: string
) {
  const options: RequestOptions = {
    method: "POST",
    body: { productId, amount, currency, consent, phone_number },
  };

  return await fetchWrapper("bids", options);
}

async function updateBid(
  bidId: string,
  amount: number,
  currency: string,
  consent: boolean = false,
  phone_number: string
) {
  const options: RequestOptions = {
    method: "PATCH",
    body: { amount, currency, consent, phone_number },
  };

  return await fetchWrapper(`bids/${bidId}`, options);
}

async function acceptBid(bidId: string, productId: string, consent: boolean) {
  const options: RequestOptions = {
    method: "PATCH",
    body: { productId, isAccepted: true, consent },
  };

  return await fetchWrapper(`bids/accept/${bidId}`, options);
}

async function deleteBid(bidId: string) {
  const options: RequestOptions = {
    method: "DELETE",
  };

  return await fetchWrapper(`bids/${bidId}`, options);
}

export const useFetchBids = (productId: string) => {
  return useQuery<any, "bids">(
    ["bids", productId],
    () => getAllBids(productId),
    {
      staleTime: 50 * 60 * 1000, // Cache for 5 minutes
    }
  );
};

// Place bid mutation
export const usePlaceBid = (dispatch: Dispatch) => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: {
      productId: string;
      amount: number;
      currency: string;
      consent: boolean;
      phone_number: string;
    }) =>
      placeBid(
        bidData.productId,
        bidData.amount,
        bidData.currency,
        bidData.consent,
        bidData.phone_number
      ),
    {
      onSuccess: (successResponse) => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
        const { success } = successResponse;
        if (success) {
          dispatch(
            showToast({
              message: TOAST_MESSAGES.BIDS.ADD_BIDS_SUCCESS,
              severity: "success",
            })
          );
        } else {
          dispatch(
            showToast({
              message: TOAST_MESSAGES.BIDS.ADD_BIDS_SUCCESS,
              severity: "error",
            })
          );
        }
      },
      onError: (error) => {
        console.error("Failed to place bid:", error);
        dispatch(
          showToast({
            message: TOAST_MESSAGES.BIDS.ADD_BIDS_SUCCESS,
            severity: "error",
          })
        );
      },
    }
  );
};

// Update bid mutation
export const useUpdateBid = (dispatch: Dispatch) => {
  const queryClient = useQueryClient();
  return useMutation(
    (bidData: {
      bidId: string;
      amount: number;
      currency: string;
      consent: boolean;
      phone_number: string;
    }) =>
      updateBid(
        bidData.bidId,
        bidData.amount,
        bidData.currency,
        bidData.consent,
        bidData.phone_number
      ),
    {
      onSuccess: (successResponse) => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
        const { success } = successResponse;
        if (success) {
          dispatch(
            showToast({
              message: TOAST_MESSAGES.BIDS.ADD_BIDS_SUCCESS,
              severity: "success",
            })
          );
        } else {
          dispatch(
            showToast({
              message: TOAST_MESSAGES.BIDS.ADD_BIDS_SUCCESS,
              severity: "error",
            })
          );
        }
      },
      onError: (error) => {
        console.error("Failed to update bid:", error);
        dispatch(
          showToast({
            message: TOAST_MESSAGES.BIDS.ADD_BIDS_SUCCESS,
            severity: "error",
          })
        );
      },
    }
  );
};

// Accept bid mutation
export const useAcceptBid = (dispatch: Dispatch) => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: { bidId: string; productId: string; consent: boolean }) =>
      acceptBid(bidData.bidId, bidData.productId, bidData.consent),
    {
      onSuccess: (successResponse) => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
        queryClient.invalidateQueries("products");
        const { success } = successResponse;
        if (success) {
          dispatch(
            showToast({
              message: TOAST_MESSAGES.BIDS.ACCEPT_BIDS_SUCCESS,
              severity: "success",
            })
          );
        } else {
          dispatch(
            showToast({
              message: TOAST_MESSAGES.BIDS.ACCEPT_BIDS_SUCCESS,
              severity: "error",
            })
          );
        }
      },
      onError: (error) => {
        console.error("Failed to accept bid:", error);
        dispatch(
          showToast({
            message: TOAST_MESSAGES.BIDS.ACCEPT_BIDS_SUCCESS,
            severity: "error",
          })
        );
      },
    }
  );
};

// Accept bid mutation
export const useDeleteBid = (dispatch: Dispatch) => {
  const queryClient = useQueryClient();

  return useMutation((bidData: { bidId: string }) => deleteBid(bidData.bidId), {
    onSuccess: (successResponse) => {
      // Invalidate and refetch bids after placing a bid
      queryClient.invalidateQueries("bids");
      const { success } = successResponse;
      if (success) {
        dispatch(
          showToast({
            message: TOAST_MESSAGES.BIDS.DELETE_BIDS_SUCCESS,
            severity: "success",
          })
        );
      } else {
        dispatch(
          showToast({
            message: TOAST_MESSAGES.BIDS.DELETE_BIDS_ERROR,
            severity: "error",
          })
        );
      }
    },
    onError: (error) => {
      console.error("Failed to accept bid:", error);
      dispatch(
        showToast({
          message: TOAST_MESSAGES.BIDS.DELETE_BIDS_ERROR,
          severity: "error",
        })
      );
    },
  });
};
