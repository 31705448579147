import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
  useMediaQuery,
} from "@mui/material";
import privacyImg from "assets/privacypolicy.png"
import Breadcrumb from "../BreadCrumbs";
import { useTheme } from "@mui/material/styles";



const PrivacyPolicy: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container maxWidth="md" >
      <Box position="static" sx={{ padding: theme.spacing(isSmallScreen?2:5) }}></Box>
      <Breadcrumb />
      <Box my={4}>
        <Grid sx={{
           backgroundColor:"#b4f0ee",
           textAlign:"center",
           p:2,
          borderRadius:"10px",
          mb:4
           
        }}>
          <img src={privacyImg} width={30} />
        <Typography variant="h1" gutterBottom 
        sx={{fontSize:"2rem",
          textAlign:"center",
          color:"black"
        }}
        >
          Privacy Policy
        </Typography>
        </Grid>
        <Typography variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography paragraph>
          We encourage you to read our Privacy Policy carefully before using our
          website and services. This document will help you understand how and
          why we collect and utilize your personal data.
        </Typography>

        <Typography variant="h5" gutterBottom>
          About This Policy
        </Typography>
        <Typography paragraph>
          This Privacy Policy is provided by Bidzbay.com ("we," "us," or "our").
          We collect and use personal data from individuals who visit our
          website or utilize our services. Personal data refers to any
          information that can identify you, either directly or indirectly.
        </Typography>
        <Typography paragraph>
          Your privacy is paramount to us. We are committed to handling your
          personal data responsibly and in compliance with applicable data
          protection laws. If you have any questions regarding your privacy,
          feel free to reach out to us at{" "}
          <a href="mailto:dataprivacy@bidzbay.com">dataprivacy@bidzbay.com</a>.
        </Typography>

        <Typography variant="h5" gutterBottom>
          What Personal Data Do We Collect and How?
        </Typography>
        <Typography variant="h6" gutterBottom>
          Information You Provide Directly
        </Typography>
        <Typography paragraph>
          We collect personal information that you provide when you fill out our
          forms, such as your name and email address in the 'Contact Us' form.
          Any additional details you choose to provide in this form may also be
          used to identify you.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Information We Collect Indirectly
        </Typography>
        <Typography paragraph>
          We gather information based on your interactions with our services, as
          well as information received from third parties. This could include
          details communicated to us verbally or in writing by these third
          parties.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Automatic Data Collection and Cookies
        </Typography>
        <Typography paragraph>
          We do not employ cookies, web beacons, or similar technologies for
          tracking while you browse our website. However, we do use cookies to
          enhance your user experience and ensure our site functions properly,
          without any tracking beyond necessary functionality.
        </Typography>

        <Typography variant="h5" gutterBottom>
          How Do We Use Your Personal Data?
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Contacting You: We use your information to respond to inquiries submitted via the contact form." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Improving Our Services: Your data may help us enhance your browsing experience and deliver content more effectively." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Securing Our Services: Your information may be utilized to detect, prevent, and address fraud or technical issues." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Legal Compliance: We may need to process your data to meet legal or regulatory obligations." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Business Analysis: We may analyze your information for business purposes, such as identifying consumer trends." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Aggregated or Anonymized Data: We might compile your data into statistical formats that do not identify you." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Legal Basis for Processing Your Personal Data
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Your explicit consent (e.g., when you contact us);" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Compliance with legal obligations;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Supporting our legitimate business interests, such as market research and analytics, while respecting your privacy rights." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Who Do We Share Your Personal Data With?
        </Typography>
        <Typography paragraph>
          We may share your information with third parties who provide services
          to us, such as IT support. This sharing is limited to what is
          necessary for them to deliver these services. For example, when you
          submit a query via our contact form, your information may be shared
          with our service providers.
        </Typography>
        <Typography paragraph>
          We may also disclose your data to affiliated companies, authorities,
          or potential buyers as required by law or during business transitions
          like mergers.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Security
        </Typography>
        <Typography paragraph>
          We implement measures to protect your information; however, we cannot
          guarantee complete security. Any data transmission is at your own
          risk. Your personal data is stored securely in a centralized cloud
          database.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Retention
        </Typography>
        <Typography paragraph>
          We will retain your data as long as necessary to fulfill the purposes
          outlined in this policy or as required by law.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Managing Your Personal Data
        </Typography>
        <Typography paragraph>
          If you wish to remove your information, please contact us with your
          request. You can also unsubscribe from communications using the link
          provided in our emails.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph>
          For any privacy-related inquiries, please reach out to us at{" "}
          <a href="mailto:dataprivacy@bidzbay.com">dataprivacy@bidzbay.com</a>.
          To protect your data, we may need to verify your identity before
          processing any requests.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Updates to This Privacy Policy
        </Typography>
        <Typography paragraph>
          We may modify this Privacy Policy at any time. We encourage you to
          review this page periodically for updates. Continued use of our
          services after any changes signifies your acceptance of the revised
          policy.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
