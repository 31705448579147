import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Geosuggest, { Suggest } from "@ubilabs/react-geosuggest";
import { useTheme } from "@mui/material/styles";
import { RootState, AppDispatch } from "../store/store";
import { setLocation, fetchLocation } from "../store/slices/location.slice";
import "./geosuggest.css";
import { useMediaQuery } from "@mui/material";
import { fetchCurrencyRates } from "../store/slices/currency.slice";

type GeosuggestDropdownProps = {
  showInput?: boolean;
  setShowInput: (state: boolean) => void | undefined;
  value?: string;
  onChange?: (value: { city: string; state: string; country: string }) => void;
  spacing?: boolean;
  hideDelete?: boolean;
  filterLocation?: boolean;
};

const GeosuggestDropdown: React.FC<GeosuggestDropdownProps> = ({
  showInput = false,
  setShowInput,
  value = "",
  spacing = false,
  onChange,
  hideDelete = false,
  filterLocation = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const trimLocation = (suggest: any) => {
    const gmaps = (suggest as any).gmaps; // Use type assertion to access gmaps
    if (gmaps) {
      const components = gmaps.address_components;
      const city = components?.find((component: any) =>
        component.types.includes("locality")
      )?.long_name;
      const state = components?.find((component: any) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;
      const country = components?.find((component: any) =>
        component.types.includes("country")
      )?.long_name;

      return { city, state, country };
    }
    return { city: "", state: "", country: "" };
  };

  useEffect(() => {
    dispatch(fetchLocation());
    dispatch(fetchCurrencyRates());
  }, [dispatch]);

  const onSuggestSelect = (suggest?: Suggest) => {
    if (suggest && suggest.description) {
      const formattedLocation = trimLocation(suggest);
      if (formattedLocation) {
        if (onChange) onChange(formattedLocation);
        else {
          dispatch(setLocation(formattedLocation));
        }
        setShowInput(false);
      }
    }
  };

  const selectedValue = useSelector(
    (state: RootState) => state.location.location
  );

  const formattedAddress = value
    ? value
    : [selectedValue?.city, selectedValue?.state, selectedValue?.country]
        .filter(Boolean)
        .join(", ");

  return (
    <Typography
      style={{
        position: "relative",
        marginRight: spacing ? 0 : theme.spacing(0),
        display: "flex",
        zIndex: "110",
        alignItems: "end",
      }}
    >
      {!showInput ? (
        <IconButton
          onClick={() => setShowInput(!showInput)}
          style={{
            marginRight: theme.spacing(1),
            color: theme.palette.text.tertiary,
          }}
        >
          {!isSmallScreen && <Typography>{formattedAddress}</Typography>}
          <LocationOnIcon />
        </IconButton>
      ) : (
        <>
          <Geosuggest
            placeholder= {isBigScreen? "Select Your Location" :"Location"}
            onSuggestSelect={onSuggestSelect}
            initialValue={!filterLocation ? formattedAddress : value}
            style={{
              input: {
                display: "block",
                padding: spacing ? "15px" : "10px",
                fontSize: "15px",
                border: "1px solid #ccc",
                borderRadius: "20px",
                margin: 1,
              },
              suggests: {
                color: "black",
              },
            }}
          />
          )
          {!hideDelete && (
            <IconButton
              onClick={() => setShowInput(false)}
              style={{
                position: "absolute",
                right: "-8px",
                background: "white",
                width: "1.5vw",
                height: "1.5vw",
                top: "10%",
                boxShadow: "0px 1px 5px 1px grey",
                transform: "translateY(-50%)",
                color: "black",
              }}
            >
              <CloseIcon sx={{ height: isSmallScreen ? "17px" : "16px" }} />
            </IconButton>
          )}
        </>
      )}
    </Typography>
  );
};

export default GeosuggestDropdown;
