import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { COUNTRY_CURRENCY } from "../../components/utils/constants/constants";
//import { getIP } from "../../components/common/SetLocationFromIp";
import { setFilters } from "./filter.slice";
import { showToast } from "./toastSlice";
import { TOAST_MESSAGES } from "../../components/utils/constants/toastMessages";

interface LocationState {
  location: {
    city: string;
    state: string;
    country: string;
  };
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: LocationState = {
  location: {
    city: "",
    state: "",
    country: "",
  },
  status: "idle",
  error: null,
};

const LOCAL_STORAGE_KEY = "currentLocation";
//const CACHE_VALIDITY_PERIOD = 24 * 60 * 60 * 1000; // 24 hours

export const fetchLocation = createAsyncThunk(
  "location/fetchLocation",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      let location: any = {};
      if (cachedData) {
        const cachedLocation = JSON.parse(cachedData);
        location = cachedLocation.location;
        //if (Date.now() - timestamp < CACHE_VALIDITY_PERIOD) {
        //}
      }
      if (!location?.country) {
        //location = await getIP();
        const position = await new Promise<GeolocationPosition>(
          (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          }
        );

        const { latitude, longitude } = position.coords;

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        const data = await response.json();

        let city = "";
        let state = "";
        let country = "";
        if (data.results && data.results[0]) {
          data.results[0].address_components.forEach(
            (component: { types: string[]; long_name: string }) => {
              if (component.types.includes("administrative_area_level_3")) {
                city = component.long_name;
              }
              if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
              }
              if (component.types.includes("country")) {
                country = component.long_name;
              }
            }
          );

          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify({
              location: {
                city,
                state,
                country,
              },
              timestamp: Date.now(),
            })
          );
          location = {
            city,
            state,
            country,
          };
        }
      }

      dispatch(
        setLocation({
          city: location?.city,
          state: location?.state,
          country: location?.country,
        })
      );
      return location;
      // const position = await new Promise<GeolocationPosition>(
      //   (resolve, reject) => {
      //     navigator.geolocation.getCurrentPosition(resolve, reject);
      //   }
      // );

      // const { latitude, longitude } = position.coords;

      // const response = await fetch(
      //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      // );
      // const data = await response.json();

      // let city = "";
      // let state = "";
      // let country = "";
      // if (data.results && data.results[0]) {
      //   data.results[0].address_components.forEach(
      //     (component: { types: string[]; long_name: string }) => {
      //       if (component.types.includes("administrative_area_level_2")) {
      //         city = component.long_name;
      //       }
      //       if (component.types.includes("administrative_area_level_1")) {
      //         state = component.long_name;
      //       }
      //       if (component.types.includes("country")) {
      //         country = component.long_name;
      //       }
      //     }
      //   );

      //   localStorage.setItem(
      //     LOCAL_STORAGE_KEY,
      //     JSON.stringify({
      //       location: {
      //         city,
      //         state,
      //         country,
      //       },
      //       timestamp: Date.now(),
      //     })
      //   );

      //   return {
      //     city,
      //     state,
      //     country,
      //   };
      // } else {
      //   throw new Error("No results found");
      // }
    } catch (error: any) {
      // dispatch(
      //   showToast({
      //     message: TOAST_MESSAGES.LOCATION.FETCH_LOCATION,
      //     severity: "error",
      //   })
      // );
      return rejectWithValue(error.message);
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation(
      state: any,
      action: PayloadAction<{ city: string; state: string; country: string }>
    ) {
      state.location = action.payload;
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          location: action.payload,
          timestamp: Date.now(),
        })
      );
      localStorage.setItem(
        "currency",
        COUNTRY_CURRENCY[
          action.payload.country as keyof typeof COUNTRY_CURRENCY
        ] || "USD"
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.location = action.payload as {
          city: string;
          state: string;
          country: string;
        };
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { setLocation } = locationSlice.actions;

export default locationSlice.reducer;
