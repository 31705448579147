import React from "react";

import { Container, Typography, Box,Link, Divider,Grid,useMediaQuery } from "@mui/material";
import AboutImg from "assets/faq.png"
import Breadcrumb from "../BreadCrumbs";
import { useTheme } from "@mui/material/styles";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { blue } from "@mui/material/colors";

const FAQ: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="md">
      
      <Box position="static" sx={{ padding: theme.spacing(isSmallScreen?2:5) }}></Box>
      <Breadcrumb />
      <Box my={4}>
        <Grid sx={{
           backgroundColor:"#b4f0ee",
           textAlign:"center",
           p:2,
          borderRadius:"10px",
          mb:4
           
        }}>
          <img src={AboutImg} width={50}/>
        <Typography variant="h1" gutterBottom 
        sx={{fontSize: isSmallScreen? "1.2rem":"2rem",
          textAlign:"center",
          color:"black"
        }}
        >
        Frequently Asked Questions
        </Typography>
        </Grid>

        <div>

       
          <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"black",fontWeight:"600"}}
        >
         1. What is BidzBay?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        BidzBay is an online marketplace that allows users to participate in competitive bidding
        to buy or sell a wide variety of products. The platform connects buyers and sellers,
        offering an auction-style process where buyers can place bids on items, and the highest
        bidder wins the item once the auction closes. Sellers can list their products, and buyers
        can find great deals through the bidding process, creating a dynamic and competitive
        shopping experience..
        </AccordionDetails>
      </Accordion>
   

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
           aria-controls="panel1-content"
           id="panel1-header"
           sx={{color:"black",fontWeight:"600"}}
        >
          2. How do I create an account?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        You can create an account by entering your email address. An OTP will be sent to your
        email for verification. Once verified, you can proceed to fill in your personal details
        and complete your profile setup to start using BidzBay.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"black",fontWeight:"600"}}
        >
          3. How do I update my account information?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        To update your account information, click on the account icon, then select "My
        Account." From there, click on "Profile" and make the necessary changes to your
        details. Once you're done, click "Save" to apply the updates.

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
           aria-controls="panel1-content"
           id="panel1-header"
           sx={{color:"black",fontWeight:"600"}}
        >
          4. How do I bid on an item?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        To bid on an item, select the item you’re interested in, click on the “Place Bid” button,
        enter your bid amount, and then confirm by clicking “Save.” Your bid will be recorded
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
           aria-controls="panel1-content"
           id="panel1-header"
           sx={{color:"black",fontWeight:"600"}}
        >
        5. What are the different payment methods accepted by BidzBay?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        BidzBay offers PayPal as a payment option at checkout. After selecting PayPal, you’ll
        be redirected to the PayPal site to log in and complete your payment. PayPal allows you
        to pay using various methods, including linked bank accounts, credit or debit cards, and
        your PayPal balance. This provides flexibility and includes buyer protection, ensuring
        that your transaction is secure and offering recourse in case of any disputes or issues
        with your purchase.

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
           aria-controls="panel1-content"
           id="panel1-header"
           sx={{color:"black",fontWeight:"600"}}
        >
          6. Can I cancel my bid?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
            Yes, you can cancel your bid. 
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"black",fontWeight:"600"}}
        >
         7. What should I do if I win an auction?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        You'll receive a confirmation email and can proceed to payment and can contact the
        seller for the shipping.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
         expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
         aria-controls="panel1-content"
         id="panel1-header"
         sx={{color:"black",fontWeight:"600"}}
        >
          8. How do I list an item for sale?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        Click on the "Sell" tab, select the category that matches the product you want to list,
        and carefully enter the required details. Upload at least two clear photos of the item to
        showcase it properly. Once you've reviewed everything, click on "Post." Your product
        will then be listed on BidzBay, and potential buyers can start viewing and bidding on
        it.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"black",fontWeight:"600"}}
        >
         9. How do I check whether an item is listed for sale or not? 
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        To check if your item has been successfully listed, click on the account icon, then select
        "My Account." From there, click on "Listed Items," where you’ll find all the products
        you’ve posted for sale displayed.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"black",fontWeight:"600"}}
        >
         10. What are the fees associated with selling on BidzBay?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        NA 
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
           aria-controls="panel1-content"
           id="panel1-header"
           sx={{color:"black",fontWeight:"600"}}
        >
         11. How do I ship an item to the buyer?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
            Shipping arrangements are handled directly between the seller and the buyer. It is up to
            both parties to decide how they want the item to be shipped and received. Sellers are
            responsible for packaging and shipping the item, and buyers should agree on the
            shipping method, cost, and timeline.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
           aria-controls="panel1-content"
           id="panel1-header"
           sx={{color:"black",fontWeight:"600"}}
        >
         12. What happens if the seller doesn't ship the item?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
         You have the contact details of the seller; you can reach out to him 
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"black"}}/>}
           aria-controls="panel1-content"
           id="panel1-header"
           sx={{color:"black",fontWeight:"600"}}
        >
         13. How do I contact BidzBay customer support?
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
         If you face any issue, you can email us on <Link sx={{color:"blue"}} href="mailto:info@bidzbay.com">support@bidzbay.com</Link>
        </AccordionDetails>
      </Accordion>


      
    </div>

        
       
        </Box>
    </Container>
  );
};

export default FAQ;
