import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import {useMediaQuery } from "@mui/material";

import Geosuggest, { Suggest } from "@ubilabs/react-geosuggest";
import { useTheme } from "@mui/material/styles";
interface LocationSearchProps {
  onLocationSelect: (location: {
    lat: number;
    lng: number;
    location: Location;
    fullAddress: string;
  }) => void;
  value: {
    lat: number;
    lng: number;
    fullAddress: string;
  };
}

interface Location {
  city: string;
  state: string;
  country: string;
}

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 25.276987,
  lng: 55.296249,
};

const LocationSearch: React.FC<LocationSearchProps> = ({
  onLocationSelect,
  value,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
    location: Location;
    fullAddress: string;
  } | null>(null);

  useEffect(() => {
    if (value?.fullAddress) {
      updateSelectedLocation(
        +value?.lat,
        +value?.lng,
        {
          city: value?.fullAddress.split(", ")[0],
          state: value?.fullAddress.split(", ")[1],
          country: value?.fullAddress.split(", ")[2],
        },
        value?.fullAddress
      );
    }
  }, [value]);

  const defaultLocation: Location = { city: "", state: "", country: "" };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Helper function to extract city, state, and country from the suggestion
  const trimLocation = (suggest: Suggest): Location => {
    const gmaps = (suggest as any).gmaps;
    if (gmaps) {
      const components = gmaps.address_components;

      const city = components?.find((component: any) =>
        component.types.includes("locality")
      )?.long_name;

      const state = components?.find((component: any) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;

      const country = components?.find((component: any) =>
        component.types.includes("country")
      )?.long_name;

      return { city: city || "", state: state || "", country: country || "" };
    }

    return defaultLocation;
  };

  // Reusable function to update selected location
  const updateSelectedLocation = (
    lat: number,
    lng: number,
    location: Location,
    fullAddress: string
  ) => {
    setSelectedLocation((prevLocation) => ({
      ...prevLocation,
      lat,
      lng,
      location: location || defaultLocation,
      fullAddress: fullAddress || "",
    }));

    onLocationSelect({
      lat,
      lng,
      location,
      fullAddress,
    });
  };

  // Handle when user selects a suggestion
  const onSuggestSelect = (suggest?: Suggest) => {
    if (suggest && suggest.location) {
      const { lat, lng } = suggest.location;
      const location = trimLocation(suggest);
      const fullAddress = suggest.description || "";

      updateSelectedLocation(lat, lng, location, fullAddress);
    }
  };

  // Handle marker drag
  const onMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    const lat = e.latLng?.lat();
    const lng = e.latLng?.lng();

    if (lat && lng) {
      const fullAddress = selectedLocation?.fullAddress || "";
      const location = selectedLocation?.location || defaultLocation;

      updateSelectedLocation(lat, lng, location, fullAddress);
    }
  };

  return (
    <div>
      {/* Location Search */}
      <Geosuggest
        placeholder="Search for a location"
        initialValue={selectedLocation?.fullAddress}
        onSuggestSelect={onSuggestSelect}
        style={{
          input: { width: isSmallScreen? "93%": "100%",  padding: isSmallScreen? "15px": "16px",marginBottom: "10px" },
        }}
        location={
          new google.maps.LatLng(
            +value?.lat || defaultCenter.lat,
            +value?.lng || defaultCenter.lng
          )
        }
        radius={20000}
      />

      {/* Google Map */}
      {selectedLocation && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={
            selectedLocation || { lat: +value?.lat, lng: +value?.lng } ||
            defaultCenter
          }
          zoom={selectedLocation ? 15 : 12}
        >
          {selectedLocation && (
            <MarkerF
              position={selectedLocation}
              draggable
              onDragEnd={onMarkerDragEnd}
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default LocationSearch;
