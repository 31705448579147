//import { FilterOptions } from "../../components/products/SubFilters/FilterOptions";
import { ICategory } from "../../components/category/category.type";
type AnyObject = { [key: string]: any };

export const flattenObject = (obj: any, parent: string = "", res: any = {}) => {
  for (let key in obj) {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

export const logOutWithClearStorage = () => {
  localStorage.removeItem("isUserLoggedIn");
  localStorage.removeItem("email");
  localStorage.removeItem("token");
};

export const dynamicFilterCreator = (filterData: ICategory[]) => {
  const result: any = {};

  filterData.forEach((category) => {
    result[category.displayName] = {
      branchOptions: [],
      othersOptions: [],
    };

    category.subcategories.forEach((subcategory) => {
      const subcategoryFields = subcategory.fields.map(
        (field) => field.displayName
      );
      if (subcategoryFields.includes("Brand")) {
        result[category.displayName].branchOptions.push({
          subcategory: subcategory.displayName,
          fields: subcategoryFields,
        });
      } else {
        result[category.displayName].othersOptions.push({
          subcategory: subcategory.displayName,
          fields: subcategoryFields,
        });
      }
    });
  });
  return result;
};

export const updateNestedValue = (obj: any, path: string, value: string) => {
  const keys = path.split(".");
  const updatedObj = { ...obj };

  let current = updatedObj;

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      current[key] = value;
    } else {
      if (!current[key]) current[key] = {};
      current[key] = { ...current[key] };
      current = current[key];
    }
  });

  return updatedObj;
};

export const flattenFilterObject = (obj: any, prefix: string = "") => {
  const result: any = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(result, flattenFilterObject(value, newKey));
    } else {
      result[newKey] = value;
    }
  }

  return result;
};

export const convertToFilterFormat = (obj: any) => {
  const flattened = flattenFilterObject(obj);

  const result: any = {
    search: obj.search || "",
    filter: {},
  };

  Object.keys(flattened).forEach((key) => {
    const value = flattened[key];
    if (value && key !== "search") {
      result.filter[key] = value;
    }
  });

  return result;
};

export const findNonEmptyValues = (
  sourceObj: Record<string, string>,
  compareObj: Record<string, string>
) => {
  const result: Record<string, string> = {};
  for (let key in sourceObj) {
    if (
      sourceObj[key] !== "" &&
      sourceObj[key] !== null &&
      sourceObj[key] !== undefined
    ) {
      result[key] = sourceObj[key];
    } else if (
      compareObj.hasOwnProperty(key) &&
      compareObj[key] !== "" &&
      compareObj[key] !== null &&
      compareObj[key] !== undefined
    ) {
      result[key] = compareObj[key];
    }
  }
  return result;
};

export const validateEmail = (email: string) => {
  let newErrors: string = "";
  if (!email) {
    newErrors = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    newErrors = "Email is not valid";
  }
  return newErrors;
};

export const getMediaType = (fileName: string): "image" | "video" | null => {
  const extension = fileName?.split(".")?.pop()?.toLowerCase();
  //const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "avif"];
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv"];

  if (videoExtensions.includes(extension || "")) {
    return "video";
  }
  return "image";
};

export function removeNullAndEmpty<T extends AnyObject>(obj: T): T {
  // Check if the input is an object
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // Create a new object to hold the cleaned values
  const cleanedObj: AnyObject = Array.isArray(obj) ? [] : {};

  // Iterate over the keys of the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Recursively clean nested objects/arrays
      const cleanedValue = removeNullAndEmpty(value);

      // Only add non-null and non-empty string values to the cleaned object
      if (cleanedValue !== null && cleanedValue !== "") {
        cleanedObj[key] = cleanedValue;
      }
    }
  }

  return cleanedObj as T;
}

export const isDateExpired = (endDate: any) => {
  if (!endDate) return false;

  const currentDate = new Date();
  const end = new Date(endDate);

  return end < currentDate;
};
