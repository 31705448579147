import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  SelectChangeEvent,
  Typography,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  COUNTRY_CURRENCY,
  FILE_CONFIG,
  currencySymbols,
} from "../../utils/constants/constants";
import { PRODUCT_FORM_STATE } from "../product.type";
import BasicDatePicker from "../../common/DatePicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getMediaType } from "../../utils/helpers";
import LocationSearch from "../../common/LocationMap";
import { useTheme } from "@mui/material/styles";
import { PhoneNumber } from "../../common/PhoneNumber";
type ProductDetailsProps = {
  handleSubmit: (formValues: any) => void;
  handleBack: () => void;
  productForm: any;
  setProductForm: (formValues: any) => void;
  productFormFields: any;
  imagePreviews: string[];
  setImagePreviews: (filePreview: any) => void;
  typeFile: string[];
  setTypeFile: (fileTypes: any) => void;
  loading: boolean;
  isEdit: boolean;
};

const ProductDetails: React.FC<ProductDetailsProps> = ({
  handleSubmit,
  handleBack,
  productForm,
  setProductForm,
  productFormFields,
  imagePreviews,
  setImagePreviews,
  typeFile,
  setTypeFile,
  loading,
  isEdit = false,
}) => {
  //const [geoEnabled, setGeoEnabled] = useState<boolean>(false);
  const [fileSizeError, setFileSizeError] = useState<string>("");
  const location = useSelector((state: RootState) => state.location.location);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useSelector(
    (state: RootState) => state.auth.currentUserDetails
  );

  useEffect(() => {
    if (!productForm.phone_number && user.phone_number) {
      setProductForm({
        ...productForm,
        phone_number: user.phone_number,
      });
    }
  }, [productForm, setProductForm, user.phone_number]);
  useEffect(() => {
    if (productForm.location) {
      setProductForm({
        ...productForm,
        currency:
          COUNTRY_CURRENCY[
            (productForm.location?.country as keyof typeof COUNTRY_CURRENCY) ||
              (location?.country as keyof typeof COUNTRY_CURRENCY)
          ] || localStorage.getItem("currency"),
      });
    }
  }, [productForm.location]);

  const handleFormReduxState = (
    name: string,
    value: string | boolean | number | Date
  ) => {
    if (name.includes("specifications")) {
      name = name.split(".")[1];
      setProductForm({
        ...productForm,
        specifications: {
          ...productForm.specifications,
          [name]: value,
        },
      });
    } else {
      setProductForm({
        ...productForm,
        [name]: value,
      });
    }
  };

  const handleDateChange = (date: Date) => {
    if (date) {
      handleFormReduxState("bidEndDate", new Date(date).toISOString());
    }
  };

  const handleNumberChange = (value: string) => {
    setProductForm({
      ...productForm,
      phone_number: value,
    });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let { name, value, type, checked, files } =
      event.target as HTMLInputElement;

      if((type === 'number' && +value < 0) || (type === 'number' && name === 'specifications.year' && +value > new Date().getFullYear())){
        value = ''
      }

    if (files) {
      const errorMessages: string[] = [];
      const fileTypes: string[] = [];
      const uploadedFiles = Array.from(files);
      if (
        productForm.images.length > 10 ||
        uploadedFiles.length + productForm.images.length > 10
      ) {
        errorMessages.push("Uploaded Images should not be more than 10");
        setFileSizeError(errorMessages.join(" "));
        return false;
      }
      const previewUrls: any = uploadedFiles.map((file) => {
        let type = file.type.split("/")[1];
        if (
          file.type.startsWith("image/") &&
          file.size > FILE_CONFIG.maxFileSize
        ) {
          errorMessages.push(`Image ${file.name} exceeds the 10 MB limit.`);
        } else if (
          file.type.startsWith("video/") &&
          file.size > FILE_CONFIG.maxVideoSize
        ) {
          errorMessages.push(`Video ${file.name} exceeds the 60 MB limit.`);
        } else {
          fileTypes.push(type);
          return URL.createObjectURL(file);
        }
      });
      if (errorMessages.length > 0) {
        setFileSizeError(errorMessages.join(" "));
      } else {
        setFileSizeError("");
        setProductForm({
          ...productForm,
          images: [...productForm.images, ...uploadedFiles],
        });
        setImagePreviews([...imagePreviews, ...previewUrls]);
        setTypeFile([...typeFile, ...fileTypes]);
      }
    } else if (type === "checkbox") {
      handleFormReduxState(name, checked.toString());
    } else {
      handleFormReduxState(name, value);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    let { name, value } = event.target;
    handleFormReduxState(name, value);
  };

  const handleRemoveMedia = (index: number) => {
    setProductForm((prevImages: any) => ({
      ...prevImages,
      images: prevImages.images.filter((_: any, i: number) => i !== index),
      removedImages: isEdit
        ? [
            ...prevImages.removedImages,
            ...imagePreviews.filter((_: any, i: number) => i === index),
          ]
        : [],
    }));
    setImagePreviews((prevPreviews: any) =>
      prevPreviews.filter((_: any, i: number) => i !== index)
    );
    setTypeFile((prevPreviews: any) =>
      prevPreviews.filter((_: any, i: number) => i !== index)
    );
  };

  const handleReset = () => {
    setFileSizeError("");
    setProductForm(PRODUCT_FORM_STATE);
  };

  const renderField = (field: any) => {
    const fieldValue = productForm.specifications[field.name] || "";
    switch (field.type) {
      case "text":
        return (
          <TextField
            label={field.displayName}
            variant="outlined"
            name={`specifications.${field.name}`}
            value={fieldValue}
            onChange={handleFieldChange}
            fullWidth
            required={field.required}
          />
        );
      case "number":
        return (
          <TextField
            label={field.displayName}
            variant="outlined"
            name={`specifications.${field.name}`}
            value={fieldValue}
            onChange={handleFieldChange}
            fullWidth
            inputProps={{ min: 0, ...(field.displayName && { max: field.name === 'year' ? new Date().getFullYear() : '' }) }}
            required={field.required}
            type="number"
          />
        );
      case "dropdown":
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel>{field.displayName}</InputLabel>
            <Select
              name={`specifications.${field.name}`}
              value={fieldValue}
              onChange={handleSelectChange}
              label={field.displayName}
            >
              {field.options.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControlLabel
            control={
              <Checkbox
                name={`specifications.${field.name}`}
                checked={fieldValue === true}
                onChange={handleFieldChange}
              />
            }
            label={field.displayName}
          />
        );
      case "button":
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => alert(`${field.displayName} clicked!`)}
          >
            {field.displayName}
          </Button>
        );
      default:
        return null;
    }
  };

  const setLocation = (location: { location: {} }) => {
    setProductForm({
      ...productForm,
      location: location?.location,
      address: {
        ...location,
      },
    });
  };

  // const validateFiles = (files: string) => {
  //   if (productForm.images && productForm.images.length > 0) {
  //     return true;
  //   } else {
  //     setFileSizeError("Please select at least one image or video.");
  //   }
  // };

  // const filterLocation = (location: any) =>
  //   [location.city, location.state, location.country]
  //     .filter(Boolean) // Remove any falsy values (e.g., undefined, null, "")
  //     .join(", ");

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={12}>
          <TextField
            label="Title"
            variant="outlined"
            name="title"
            value={productForm.title || ""}
            onChange={handleFieldChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={productForm.description || ""}
            onChange={handleFieldChange}
            fullWidth
            multiline
            rows={4}
            required
          />
        </Grid>
        {/* <Grid item xs={4} sm={4}>
          <Select
            labelId="currency-label"
            id="currency"
            name="currency"
            value={productForm.currency || "USD"}
            onChange={handleSelectChange}
            label="Select Currency"
            fullWidth
          >
            {Object.entries(COUNTRY_CURRENCY).map(([currency, val], index) => (
              <MenuItem value={val.toUpperCase()} key={`${currency}-${index}`}>
                {val.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <TextField
            label="Price"
            name="price"
            variant="outlined"
            value={productForm.price || ""}
            onChange={handleFieldChange}
            fullWidth
            required
            type="number"
            inputProps={{ min: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {
                    currencySymbols[
                      productForm.currency ||
                        localStorage.getItem("currency") ||
                        "USD"
                    ]
                  }
                </InputAdornment>
              ),
              inputProps: { min: 1 },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          {/* <GeosuggestDropdown
            showInput={true}
            setShowInput={() => {}}
            spacing={true}
            hideDelete={true}
            value={filterLocation(productForm.location) || ""}
            onChange={setLocation}
            filterLocation={false}
          /> */}
          <LocationSearch
            value={productForm.address}
            onLocationSelect={setLocation}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PhoneNumber
            onChange={handleNumberChange}
            number={productForm.phone_number}
          />
          <FormHelperText>Phone number</FormHelperText>
          {/* <TextField
            fullWidth
            type="tel"
            label="Phone Number"
            name="phone_number"
            required
            value={productForm.phone_number || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length <= 15) {
                handleFieldChange(e);
              }
            }}
            helperText={
              "Please enter a phone number with country code like +919988776655"
            }
          /> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          {/* <TextField
            label="Tag"
            variant="outlined"
            name="tag"
            value={productForm.tag || ""}
            onChange={handleFieldChange}
            fullWidth
          /> */}
          <BasicDatePicker
            label="Bidding Ends On"
            onChange={handleDateChange}
            name="bidEndDate"
            value={dayjs(productForm?.bidEndDate) || dayjs().add(15, "day")}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            component="label"
            startIcon={<PhotoCamera />}
            fullWidth
            disabled={productForm.images.length > 10}
          >
            Upload Videos & Images{" "}
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
            <span
              style={{
                fontSize: isSmallScreen ? "5px" : "10px",
                marginLeft: isSmallScreen ? "5px" : "10px",
                color: "yellow",
              }}
            >
              (Max 10)
            </span>
            <input
              type="file"
              hidden
              multiple
              accept="image/*,video/*,.gif"
              onChange={handleFieldChange}
            />
          </Button>
          <FormHelperText>
            This is required field, max 10 files allowed. Only images or videos
            are allowed.
          </FormHelperText>
          {fileSizeError && (
            <Typography variant="body2" color="error">
              {fileSizeError}
            </Typography>
          )}
          <Box mt={2} display="flex" flexWrap="wrap" justifyContent={"center"}>
            {imagePreviews.length > 0 && (
              <>
                {imagePreviews.map((src, index) => {
                  const type =
                    typeFile.length > 0 && typeFile[index]
                      ? getMediaType(`test.${typeFile[index]}`)
                      : getMediaType(src);
                  const isImage = type === "image";
                  return (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        mr: 2,
                        mb: 2,
                      }}
                    >
                      {isImage ? (
                        <img
                          src={src}
                          alt={`preview-${index}`}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <video
                          src={src}
                          controls
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <IconButton
                        onClick={() => handleRemoveMedia(index)}
                        sx={{
                          position: "absolute",
                          top: 1,
                          right: 0,
                          color: "red",
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Grid>

        {/* Dynamic Fields */}
        {/* <Grid item xs={12} sm={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Subcategory</InputLabel>
            <Select
              value={productForm?.subcategory?.uuid || ""}
              onChange={handleSubcategoryChange}
              label="Subcategory"
            >
              {categoryData.subcategories.map((sub: any) => (
                <MenuItem
                  key={sub.uuid}
                  value={sub.uuid}
                  selected={selectedSubcategory?.uuid === sub.uuid}
                >
                  {sub.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        {productFormFields &&
          productFormFields.map((field: any) => (
            <Grid item xs={12} sm={12} key={field.name}>
              {renderField(field)}
            </Grid>
          ))}
        <Box>
          <Grid container spacing={2} marginTop={3} justifyContent="flex-end">
            <Grid item>
              <Button onClick={handleBack}>Back</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Post Now"}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleReset}>Reset</Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default ProductDetails;
