import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GeosuggestDropdown from "./GeosuggestDropdown";
import GeosuggestDropdownMobile from "./Geosuggestmobile";
import logo from "../assets/logo512.png";
//import AppsSharpIcon from "@mui/icons-material/AppsSharp";
import { useNavigate } from "react-router-dom";
import AllCategory from "./category/CategoryAll";
import MobileMenu from "./products/MobileMenu";
//import Notifications from "./products/Notifications";
import LoggedInMenu from "./auth/LoggedInMenu";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { useDispatch } from "react-redux";
import { setLoginModelVisibility } from "../store/slices/auth.slice";
//import { AccountMenu } from "./auth/Account";
//import { useGoogleLogin } from "./auth/GoogleLoginContext";
//import CurrencyConverter from "./CurrencyConverter";
//import { GetIp } from "./GetIp";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [showInput, setShowInput] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);

  //const [showCategory, setShowCategory] = useState(false);
  //const [isSticky, setIsSticky] = useState(false);
  // const isUserLoggedIn: boolean = useSelector(
  //   (state: RootState) => state.auth.user.isUserLoggedIn
  // );

  // const handleScroll = () => {
  //   const filterOffsetTop = 64; // Height of the AppBar
  //   if (window.scrollY > filterOffsetTop) {
  //     setIsSticky(true);
  //   } else {
  //     setIsSticky(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleSellClick = () => {
    if (user.isUserLoggedIn) {
      navigate("/add-product");
    } else {
      handleLoginClick();
    }
  };

  const handleLoginClick = () => {
    dispatch(setLoginModelVisibility({ showLogin: true }));
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            {/* <Button
              startIcon={<AppsSharpIcon />}
              onClick={() => setShowCategory(!showCategory)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
              }}
              title="Category Filter"
            ></Button> */}
            {isSmallScreen && <MobileMenu />}
            <img
              src={logo}
              alt="BidzBay Logo"
              style={{ height: !isBigScreen ? "6vh" : "8vh" }}
              onClick={() => navigate("/")}
            />
            {!isSmallScreen && (
              <Typography
                variant="h6"
                component="div"
                sx={{ marginLeft: 2 }}
                onClick={() => navigate("/")}
              >
                BidzBay
              </Typography>
            )}
          </Box>

          {/* {!isSmallScreen && <AllCategory />} */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "auto",
            }}
          >
            {!isSmallScreen ?
            (<GeosuggestDropdown
              showInput={showInput}
              setShowInput={(value: boolean) => setShowInput(value)}
            />):( <GeosuggestDropdownMobile 
              showInput={showInput}
              setShowInput={(value: boolean) => setShowInput(value)}/>)
            }
            {/* <Notifications /> */}

            {/* <CurrencyConverter /> */}

            <Button
              color="inherit"
              variant="outlined"
              onClick={handleSellClick}
            >
              SELL
            </Button>

            {user.isUserLoggedIn ? (
              <LoggedInMenu />
            ) : (
              <Button color="inherit" onClick={handleLoginClick}>
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {!isSmallScreen && <AllCategory />}
    </>
  );
};

export default Header;
