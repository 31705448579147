import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Box,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface ContactUsFormProps {
  open: boolean;
  handleClose: () => void;
  formData: { [key: string]: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  errors: { [key: string]: string };
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  open = false,
  handleClose,
  formData,
  handleChange,
  handleSubmit,
  errors,
}) => {
  const getProduct = () => {
    //const encodedMessage = encodeURIComponent(`${window.location.hostname}/`);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP}&text=Hello, Bidzbay Team! 🌟 I’m reaching out to request your valuable assistance with your website, https://bidzbay.com. I’d greatly appreciate any guidance or support you can provide, as I’m eager to make the most of your platform. Thank you so much for your time and attention—I look forward to hearing from you soon!`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Get personalized advice</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Submit your details, we will get in touch or Connect Via{" "}
          <Button variant="contained" size="small" onClick={() => getProduct()}>
            <WhatsAppIcon />
          </Button>
        </DialogContentText>
        <Box component="form" sx={{ mt: 2 }}>
          <TextField
            margin="dense"
            name="firstName"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Phone No."
            type="tel"
            fullWidth
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={
              errors.phone ||
              "Please enter a phone number with country code like +919988776655"
            }
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="note"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={formData.note}
            onChange={handleChange}
            error={!!errors.note}
            helperText={errors.note}
            sx={{ mb: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{ fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactUsForm;
