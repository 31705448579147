import React, { useMemo } from "react";
import {
  MuiTelInput,
  type MuiTelInputCountry,
  type MuiTelInputInfo,
  type MuiTelInputContinent,
  type MuiTelInputFlagElement,
} from "mui-tel-input";
import FlagIcon from "@mui/icons-material/Flag";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { countryCodes } from "../utils/country-code.constant";

export const PhoneNumber: React.FC<{
  onChange: (value: string) => void;
  number?: string;
}> = ({ onChange, number }) => {
  const location = useSelector((state: RootState) => state.location.location);

  const defaultCountryCode = useMemo(() => {
    if (location?.country) {
      const selectedCountry = countryCodes.find(
        (country) => country.label === location.country
      );
      return selectedCountry?.value ?? undefined; // Ensures return is never undefined
    }
    return undefined;
  }, [location]); // Added location and countryCodes as dependencies

  const handleChange = (newValue: string, info: MuiTelInputInfo) => {
    onChange(newValue);
  };

  const continents: MuiTelInputContinent[] = [
    "EU",
    "AS",
    "NA",
    "SA",
    "OC",
    "AF",
  ];
  const excludedCountries: MuiTelInputCountry[] = [];
  // It's also the return value for the `getFlagElement` function prop
  const unknownFlag: MuiTelInputFlagElement = (
    <>
      <FlagIcon titleAccess="Select Country" />
      <ExpandMoreIcon fontSize="small" />
    </>
  );

  return (
    <MuiTelInput
      value={number || defaultCountryCode}
      onChange={handleChange}
      continents={continents}
      unknownFlagElement={unknownFlag}
      excludedCountries={excludedCountries}
      placeholder="Enter Phone Number"
    />
  );
};
