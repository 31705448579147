import React from "react";

import { Container, Typography,Link, Box,List,ListItem, Divider,Grid,useMediaQuery } from "@mui/material";
import tncImg from "assets/tnc.png"
import Breadcrumb from "../BreadCrumbs";
import { useTheme } from "@mui/material/styles";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TermsOfUse: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="md">
      
      <Box position="static" sx={{ padding: theme.spacing(isSmallScreen?2:5) }}></Box>
      <Breadcrumb />
      <Box my={4}>
        <Grid sx={{
           backgroundColor:"#b4f0ee",
           textAlign:"center",
           p:2,
          borderRadius:"10px",
          mb:4
           
        }}>
          <img src={tncImg} width={50}/>
        <Typography variant="h1" gutterBottom 
        sx={{fontSize: isSmallScreen? "1.2rem":"2rem",
          textAlign:"center",
          color:"black"
        }}
        >
          Bidzbay Terms Of Use
        </Typography>
        </Grid>
        
        <Box mt={4} mb={4}>
        
        <Typography variant="subtitle1">
          <strong>Effective Date:</strong> 1 October 2024
        </Typography>

        <Typography variant="body1" paragraph>
          These Bidzbay.com Platform Terms of Use (the "Terms of Use") outline the rules for using the platform available at{' '}
          <Link href="https://www.bidzbay.com" target="_blank" rel="noopener">
            www.bidzbay.com
          </Link>{' '}
          and through any Bidzbay.com mobile application (collectively the "Platform") as well as any information content or materials published on or accessible via the Platform (collectively the "Content").
        </Typography>

        <Typography variant="body1" paragraph>
          These Terms of Use govern your access to and use of the Platform and its Content.
        </Typography>

        <Typography variant="h5" gutterBottom>
          WHO WE ARE AND HOW TO CONTACT US
        </Typography>
        <Typography variant="body1" paragraph>
          The Platform is operated and owned by Gulf Land Commercial ("Bidzbay.com," "we," "our," "us"). Our mailing address is Floor 7, Building 241, Al Khuwair, Muscat, Oman.
        </Typography>
        <Typography variant="body1" paragraph>
          For inquiries regarding these Terms of Use or any other matter related to your use of the Platform, please email{' '}
          <Link href="mailto:support@bidzbay.com">support@bidzbay.com</Link>.
        </Typography>

        <Typography variant="h5" gutterBottom>
          BY USING THE PLATFORM YOU ACCEPT THESE TERMS OF USE
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing and using the Platform, you confirm that you accept these Terms of Use and acknowledge that they govern your access to and use of the Platform and any Content. These Terms of Use constitute a legally binding agreement between you and Bidzbay.com. If you are using the Platform as a business entity, you represent that you have the authority to bind that entity.
        </Typography>
        <Typography variant="body1" paragraph>
          If you do not agree to these Terms of Use, you must stop using the Platform and its Content immediately.
        </Typography>

        <Typography variant="h5" gutterBottom>
          WE MAY MAKE CHANGES TO THESE TERMS OF USE
        </Typography>
        <Typography variant="body1" paragraph>
          We may amend these Terms of Use at any time with or without notice. The updated Terms will be posted on this page. If you are a registered user, we may notify you of significant changes via email where legally required. Please check these Terms of Use whenever you use the Platform to ensure you understand the terms applicable at that time.
        </Typography>

        <Typography variant="h5" gutterBottom>
          WE MAY MAKE CHANGES TO THE PLATFORM
        </Typography>
        <Typography variant="body1" paragraph>
          We may modify the Platform's operation and/or any Content without notice to reflect updates to features, technology, market practices, or our business priorities.
        </Typography>

        <Typography variant="h5" gutterBottom>
          WE MAY SUSPEND OR WITHDRAW THE PLATFORM
        </Typography>
        <Typography variant="body1" paragraph>
          We do not guarantee the uninterrupted availability of the Platform or any Content. We may suspend, withdraw, or limit access to any part of the Platform for business or operational reasons.
        </Typography>

        <Typography variant="h5" gutterBottom>
          YOUR PRIVACY
        </Typography>
        <Typography variant="body1" paragraph>
          For details on how we collect, process, and share your personal data, please refer to our Privacy Policy.
        </Typography>

        <Typography variant="h5" gutterBottom>
          YOUR RIGHT TO USE THE PLATFORM AND CONTENT
        </Typography>
        <Typography variant="body1" paragraph>
          Subject to your compliance with these Terms of Use, Bidzbay.com grants you a personal, limited, non-transferable, non-exclusive, revocable right to use the Platform and Content. You may print one (1) copy of and download extracts from any page(s) on the Platform for personal use. You must not modify any printed or downloaded materials and you may not use any illustrations, photographs, videos, or audio separately from the accompanying text. Commercial use of any Content requires our prior written consent.
        </Typography>
        <Typography variant="body1" paragraph>
          Bidzbay.com owns all rights (including intellectual property rights) in the Platform and Content. The Platform and Content are protected by copyright laws worldwide. All rights are reserved.
        </Typography>
        <Typography variant="body1" paragraph>
          All trademarks, logos, and service marks displayed on the Platform are either our property or that of third parties. You may not use these Marks without our prior written consent.
        </Typography>

        <Typography variant="h5" gutterBottom>
          RESTRICTIONS
        </Typography>
        <List>
          <ListItem>You must not use the Platform if you are under eighteen (18).</ListItem>
          <ListItem>You must not use the Platform unlawfully or for any illegal purpose.</ListItem>
          <ListItem>Attempt unauthorized access to the Platform or any connected servers or databases.</ListItem>
          <ListItem>Interfere with or disrupt the Platform or its Content.</ListItem>
          <ListItem>Introduce malicious software (e.g. viruses, trojans).</ListItem>
          <ListItem>Impersonate any person while using the Platform.</ListItem>
          <ListItem>Conduct yourself in an offensive or harassing manner.</ListItem>
          <ListItem>Generate unsolicited advertisements or spam.</ListItem>
          <ListItem>Reproduce, sell, or exploit any part of the Platform or Content for commercial purposes.</ListItem>
          <ListItem>Use automated means to scrape the Platform or its Content.</ListItem>
          <ListItem>Reverse-engineer, decompile, or disassemble the Platform or its Content.</ListItem>
        </List>
        <Typography variant="body1" paragraph>
          We will report any breaches of these restrictions to law enforcement authorities.
        </Typography>

        <Typography variant="h5" gutterBottom>
          ACCOUNT CREATION
        </Typography>
        <Typography variant="body1" paragraph>
          To access certain features of the Platform, you must create an account ("Account"). You must provide accurate and truthful information during the registration process. If you create an Account, you are responsible for maintaining its confidentiality, including your password. You are liable for all activities that occur under your Account. Notify us immediately of any unauthorized use of your Account. All information provided during Account creation will be handled according to our Privacy Policy.
        </Typography>

        <Typography variant="h5" gutterBottom>
          PAID SERVICES
        </Typography>
        <Typography variant="body1" paragraph>
          Bidzbay.com may offer paid services on the Platform ("Paid Service"). Fees for these services are non-refundable and do not guarantee successful listings or minimum leads.
        </Typography>

        <Typography variant="h5" gutterBottom>
          LISTINGS
        </Typography>
        <Typography variant="body1" paragraph>
          The Platform allows users to advertise items or services ("Listings"). Sellers must ensure they are legally permitted to sell the item or service, provide accurate descriptions, and comply with our Acceptable Use Policy. Buyers must also act legally and in good faith. Bidzbay.com is not responsible for the Listings on the Platform. Purchases are made directly from the user who posted the Listing. We reserve the right to monitor and remove Listings that violate these Terms.
        </Typography>

        <Typography variant="h5" gutterBottom>
          UPLOADING CONTENT TO THE PLATFORM
        </Typography>
        <Typography variant="body1" paragraph>
          Any content you upload is considered non-confidential. You retain ownership but grant us a worldwide, non-exclusive license to use that content. You are responsible for backing up your content. We reserve the right to remove content that does not comply with these Terms.
        </Typography>

        <Typography variant="h5" gutterBottom>
          ACCEPTABLE USE POLICY
        </Typography>
        <Typography variant="body1" paragraph>
          You must comply with our Acceptable Use Policy when uploading content or interacting with other users. You are liable for any breaches.
        </Typography>

        <Typography variant="h5" gutterBottom>
          PROCESSING PAYMENTS
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect payments via the Platform. By providing payment details, you authorize us to charge your payment method.
        </Typography>

        <Typography variant="h5" gutterBottom>
          OUR LIABILITY TO YOU
        </Typography>
        <Typography variant="body1" paragraph>
          We do not limit our liability where it would be unlawful to do so. If you use the Platform in a business capacity, we exclude all implied warranties and are not liable for indirect losses. For personal use, we are only responsible for foreseeable losses resulting from our breach of these Terms. Bidzbay.com's total liability is limited to the greater of the amount paid for the relevant Paid Service or ten OMR (OMR 10).
        </Typography>

        <Typography variant="h5" gutterBottom>
          BREACH OF THESE TERMS OF USE
        </Typography>
        <Typography variant="body1" paragraph>
          If we believe you are in breach of these Terms, we may take appropriate action including suspending your access or taking legal action.
        </Typography>

        <Typography variant="h5" gutterBottom>
          REPORTING ILLEGAL OR INFRINGING CONTENT
        </Typography>
        <Typography variant="body1" paragraph>
          If you find illegal content, please contact us at <Link href="mailto:support@bidzbay.com">support@bidzbay.com</Link>. For intellectual property infringements, notify us with full details.
        </Typography>

        <Typography variant="h5" gutterBottom>
          GENERAL
        </Typography>
        <Typography variant="body1" paragraph>
          Links to third-party content are for convenience and do not imply our endorsement. You may link to the Platform in a legal manner but may not frame it. We are not responsible for viruses or events outside our control.
        </Typography>

        <Typography variant="h5" gutterBottom>
          ADDITIONAL TERMS
        </Typography>
        <Typography variant="body1" paragraph>
          Annexes to these Terms contain additional conditions for specific Platform features such as Property for Sale, Motors, and Jobs. For specific terms, refer to the respective Annexes.
        </Typography>

        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         PROPERTY FOR SALE & RENT
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        <Typography variant="body1" paragraph>
          The ‘Property for Sale’ and ‘Property for Rent’ sections of the Platform allow property owners, brokers, real estate agents, and developers to advertise properties while prospective buyers or renters can view listings, contact the owner or agent, and arrange viewings.
        </Typography>
        <Typography variant="body1" paragraph>
          Owners: If you are a property owner, you acknowledge and agree that:
        </Typography>
        <List>
          <ListItem>You are the registered owner or landlord of the property listed.</ListItem>
          <ListItem>You may only advertise properties that are currently available for sale or lease.</ListItem>
          <ListItem>You may only advertise properties that can be lawfully sold or leased in compliance with all applicable restrictions (e.g. limits on the number of occupants).</ListItem>
        </List>

        <Typography variant="body1" paragraph>
          Brokers / Real Estate Agents / Developers: If you are a broker, agent, or developer you acknowledge and agree that:
        </Typography>
        <List>
          <ListItem>You must hold a valid license from the relevant real estate authority to advertise properties on the Platform along with any necessary commercial licenses.</ListItem>
          <ListItem>For ‘off-plan’ properties, you must ensure the project is registered and authorized by the appropriate real estate authority.</ListItem>
          <ListItem>You may only advertise properties that are currently available for sale or lease.</ListItem>
          <ListItem>You may only advertise properties that can be lawfully sold or leased in compliance with all applicable restrictions.</ListItem>
        </List>

        <Typography variant="body1" paragraph>
          Buyers / Renters: If you are a prospective buyer or renter you acknowledge and agree that:
        </Typography>
        <List>
          <ListItem>Bidzbay.com only provides the Platform for property owners and agents to advertise listings; it is not a real estate broker.</ListItem>
          <ListItem>Bidzbay.com is not responsible for verifying the accuracy or completeness of listings and you should conduct your own due diligence.</ListItem>
        </List>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{mt:1}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
        MOTORS
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        <Typography variant="body1" paragraph>
          The ‘Motors’ section of the Platform allows sellers to advertise vehicles for sale while prospective buyers can view vehicle information and contact sellers.
        </Typography>
        <Typography variant="body1" paragraph>
          Sellers: If you are a seller you acknowledge and agree that any vehicle you list must:
        </Typography>
        <List>
          <ListItem>Be roadworthy.</ListItem>
          <ListItem>Be legally owned by you or by the person you are representing.</ListItem>
          <ListItem>Be available for sale.</ListItem>
          <ListItem>Be located in your country.</ListItem>
          <ListItem>Be accurately described in your listing.</ListItem>
        </List>

        <Typography variant="body1" paragraph>
          Bidzbay.com may request proof of ownership or that the vehicle is located in your country and has cleared local customs. If you fail to provide such proof within a reasonable timeframe we may remove your listing without notice or refund.
        </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{mt:1}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         JOBS
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        <Typography variant="body1" paragraph>
          The ‘Jobs’ section of the Platform allows employers and recruiters to advertise job openings while job seekers can apply for these positions.
        </Typography>
        <Typography variant="body1" paragraph>
          Employers / Recruiters: If you are an employer or recruiter you acknowledge and agree that:
        </Typography>
        <List>
          <ListItem>Bidzbay.com is not responsible for the information provided by job seekers in their applications or CVs and does not guarantee the suitability of candidates.</ListItem>
          <ListItem>The ‘CV Search’ feature is intended solely as a tool for browsing CVs in our database.</ListItem>
          <ListItem>Any fees paid are non-refundable even if you do not find a suitable candidate.</ListItem>
        </List>

        <Typography variant="body1" paragraph>
          Job Seekers: If you are a job seeker you acknowledge and agree that:
        </Typography>
        <List>
          <ListItem>By placing a job-seeking ad, employers and recruiters will have access to your personal details and CV.</ListItem>
          <ListItem>Any fees paid are non-refundable even if you do not secure a job position.</ListItem>
        </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{height:"1rem"}}/>

      <Accordion sx={{mt:1,color:"black"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"white"}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{backgroundColor:"#1A4847",color:"white"}}
        >
         Vehicle Inspection Terms and Conditions
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        <Typography variant="body1" paragraph>
        BY CLICKING ON THE ‘CONFIRM’ OR ‘ACCEPT’ BUTTON ON THIS PAGE OR BY USING OUR VEHICLE INSPECTION SERVICE (THE "SERVICE") FOR THE VEHICLE YOU HAVE IDENTIFIED (THE "VEHICLE") YOU CONSENT TO AND AGREE WITH THE VEHICLE INSPECTION TERMS AND CONDITIONS OUTLINED BELOW (THE "VEHICLE INSPECTION TERMS"). THESE TERMS FORM A LEGALLY BINDING AGREEMENT BETWEEN YOU AND GULF LAND COMMERCIAL ("Bidzbay" OR "we"). IF YOU DO NOT AGREE TO ALL THE TERMS STATED BELOW PLEASE DO NOT USE OUR VEHICLE INSPECTION SERVICE.
      </Typography>
      
      <Typography variant="body1" paragraph>
        IF YOU ARE ENTERING INTO THESE VEHICLE INSPECTION TERMS AS AN INDIVIDUAL "you" REFERS TO YOU AS A PRIVATE PERSON. IF YOU ARE ACTING ON BEHALF OF A CORPORATE ENTITY OR OTHER ORGANIZATION "you" REFERS TO THAT ENTITY AND YOU REPRESENT THAT YOU ARE AUTHORIZED TO ACT ON ITS BEHALF.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Authorization to Inspect the Vehicle
      </Typography>
      
      <Typography variant="body1" paragraph>
        You authorize Bidzbay to store, test drive, and inspect the Vehicle to deliver the Service. If the Vehicle is owned by someone else, you confirm that the owner has consented to Bidzbay storing, test driving, and inspecting the Vehicle for this purpose.
      </Typography>

      <Typography variant="body1" paragraph>
        You acknowledge that Bidzbay will not conduct a road test or inspection if the Vehicle appears to be unroadworthy (as reasonably determined by Bidzbay) or if it is not registered with the relevant authority.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Scope of the Inspection and Limitations
      </Typography>
      
      <Typography variant="body1" paragraph>
        The inspection is limited to the items listed in the Inspection Report (as defined below) and does not cover any other items. The Service involves a visual, non-mechanical inspection which may include a limited road test. If you require a mechanical inspection or an internal examination of the Vehicle, we recommend contacting a service provider specializing in those inspections.
      </Typography>

      <Typography variant="body1" paragraph>
        You agree that Bidzbay will not remove or disassemble any parts of the Vehicle during the inspection. We will inform you of any further limitations regarding the inspection, such as restricted access or the need for specialized tools.
      </Typography>

      <Typography variant="body1" paragraph>
        Bidzbay will not review historical service or accident records for the Vehicle, nor will it check for any recall notices as part of the inspection.
      </Typography>

      <Typography variant="body1" paragraph>
        You acknowledge that while Bidzbay employs recognized methods for inspections, these may not identify all issues. Specifically, the Service will not cover intermittent problems that may not be evident during the inspection.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Risk
      </Typography>

      <Typography variant="body1" paragraph>
        You or your authorized representatives must be present throughout the Vehicle inspection. The Service is provided at your own risk. Any risks or liabilities related to the Vehicle during the Service, including damage or theft of the Vehicle or its contents, remain with you.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Inspection Report
      </Typography>

      <Typography variant="body1" paragraph>
        Upon completion of the inspection, Bidzbay will create an inspection report for the Vehicle (the "Inspection Report"). All rights to the Inspection Report and its contents remain with Bidzbay. 
      </Typography>

      <Typography variant="body1" paragraph>
        The Inspection Report reflects Bidzbay’s opinion on the Vehicle's condition (limited to the items in the report) based on its age and condition at the time of inspection, as well as the inspector’s expertise. It is intended to help you understand the general condition of the Vehicle. Bidzbay does not provide recommendations regarding whether to sell or purchase the Vehicle.
      </Typography>

      <Typography variant="body1" paragraph>
        Bidzbay can only comment on the Vehicle's condition at the time of inspection. If you consider purchasing the Vehicle, it is your responsibility to conduct an additional inspection at that time.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Reliance on the Inspection Report and Liability
      </Typography>

      <Typography variant="body1" paragraph>
        If the Inspection Report is provided directly by Bidzbay, you agree that only you may rely on its content and Bidzbay will not be liable to any third parties with whom you share the report. If the report is obtained from another source, you acknowledge that you cannot rely on its content and Bidzbay disclaims any liability in relation to it.
      </Typography>

      <Typography variant="body1" paragraph>
        You agree that the Inspection Report is provided "as is" for informational purposes only without any warranties. In particular, Bidzbay does not guarantee the accuracy or completeness of any information in the report or its suitability for any purpose. To the maximum extent allowed by law, Bidzbay will not be liable for any claims arising from your use of the Inspection Report.
      </Typography>

      <Typography variant="body1" paragraph>
        To the fullest extent permitted by applicable law, Bidzbay’s liability, along with that of its affiliates, officers, directors, employees, and agents in relation to the Service will not exceed the lesser of: (i) 10% the fee you paid for the Service; or (ii) OMR 10.
      </Typography>

      <Typography variant="body1" paragraph>
        These Vehicle Inspection Terms are governed by the laws of the ROP OMAN and any disputes or claims arising will be subject to the exclusive jurisdiction of the Oman courts.
      </Typography>
        </AccordionDetails>
      </Accordion>
       
      <Accordion sx={{mt:1}}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"white"}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{backgroundColor:"#1A4847",color:"white"}}
        >
         Information About RTO 
        </AccordionSummary>
        <AccordionDetails sx={{color:"black"}}>
        <div>
      <Typography variant="h6">Vehicle Registration</Typography>
      <Typography paragraph>
        Buyers should provide the necessary documents to register the vehicle with the RTO. Sellers should ensure that the vehicle is registered and that all necessary paperwork is in order.
      </Typography>

      <Typography variant="h6">Transfer of Ownership</Typography>
      <Typography paragraph>
        If the vehicle is being sold, the seller must complete the transfer of ownership process with the RTO. This involves submitting the necessary documents and paying the required fees.
      </Typography>

      <Typography variant="h6">Vehicle Insurance</Typography>
      <Typography paragraph>
        Both buyers and sellers should ensure that the vehicle is adequately insured. Buyers should obtain insurance before taking possession of the vehicle. Sellers should ensure that the insurance policy is up-to-date and that there are no outstanding claims.
      </Typography>

      <Typography variant="h6">Vehicle Fitness Certificate</Typography>
      <Typography paragraph>
        Vehicles older than 15 years require a fitness certificate from the RTO. Sellers should ensure that the vehicle has a valid fitness certificate.
      </Typography>

      <Typography variant="h6">No Claims Bonus (NCB)</Typography>
      <Typography paragraph>
        If the seller has a clean insurance history, they may be eligible for an NCB. This can be transferred to the buyer if they purchase insurance from the same company.
      </Typography>

      <Typography variant="h6">Pollution Under Control (PUC) Certificate</Typography>
      <Typography paragraph>
        Vehicles must have a valid PUC certificate. Sellers should ensure that the vehicle has a valid PUC certificate.
      </Typography>

      <Typography variant="h6">Hypothecation</Typography>
      <Typography paragraph>
        If the vehicle is financed, it will be hypothecated to the lender. The seller must obtain a NOC from the lender before transferring ownership.
      </Typography>

      <Typography variant="h6">Vehicle Tax</Typography>
      <Typography paragraph>
        Buyers and sellers should ensure that the vehicle tax is up-to-date. Sellers should provide the buyer with a receipt for the payment of vehicle tax.
      </Typography>

      <Typography variant="h6">Vehicle Inspection</Typography>
      <Typography paragraph>
        Before purchasing a vehicle, buyers should have it inspected by a mechanic to ensure that it is in good condition.
      </Typography>
    </div>
        </AccordionDetails>
      </Accordion>
       

      </Box>
        
        </Box>
    </Container>
  );
};

export default TermsOfUse;
