// src/App.tsx
import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import BackgroundVideo from "./BackgroundVideo";
import ContentSection from "../ContentSection";
import videoSrc from "../../assets/BidzBay.mp4";
import AdviceCard from "./AdviceCard";
import ExclusiveDeals from "./ExclusiveDeals";
import PopularCategories from "./PopularCategories";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { clearFilters } from "../../store/slices/filter.slice";
import { useQuery } from "react-query";
import { fetchExclusiveProducts } from "../../store/slices/product.slice";
import LocationPrompt from "../common/LocationPrompt";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { location } = useSelector((state: RootState) => state.location);

  const { refetch } = useQuery(
    "fetchExclusiveProducts",
    () => dispatch(fetchExclusiveProducts()),
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  return (
    <>
      <BackgroundVideo src={process.env.REACT_APP_LANDING_PAGE_VIDEO_URL || 'https://d3o94m3z9vvjvz.cloudfront.net/video/BidzBay.mp4'} />

      <Container
        sx={{
          position: "relative",
          zIndex: 1,
          textAlign: "center",
          paddingTop: "10%",
          height: isSmallScreen ? "35vh" : isBigScreen ? "60vh" : "30vh",
        }}
      >
        <ContentSection />
      </Container>
      <PopularCategories />
      <AdviceCard />
      <Grid
        sx={{
          margin: "10px",
        }}
      >
        <ExclusiveDeals />
        {!location?.country && <LocationPrompt />}
      </Grid>

      {/* <ContactUs /> */}
    </>
  );
};

export default Dashboard;
