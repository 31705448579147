import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Geosuggest, { Suggest } from "@ubilabs/react-geosuggest";
import { useTheme } from "@mui/material/styles";
import { RootState, AppDispatch } from "../store/store";
import { setLocation, fetchLocation } from "../store/slices/location.slice";
import "./geosuggest.css";
import { useMediaQuery } from "@mui/material";
import { fetchCurrencyRates } from "../store/slices/currency.slice";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Height } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import { alignProperty } from "@mui/material/styles/cssUtils";


type Anchor = 'top';

type GeosuggestDropdownProps = {
  showInput?: boolean;
  setShowInput: (state: boolean) => void | undefined;
  value?: string;
  onChange?: (value: { city: string; state: string; country: string }) => void;
  spacing?: boolean;
  hideDelete?: boolean;
};

const GeosuggestDropdown: React.FC<GeosuggestDropdownProps> = ({
  showInput = false,
  setShowInput,
  value = "",
  spacing = false,
  onChange,
  hideDelete = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const trimLocation = (suggest: any) => {
    const gmaps = (suggest as any).gmaps; // Use type assertion to access gmaps
    if (gmaps) {
      const components = gmaps.address_components;
      const city = components?.find((component: any) =>
        component.types.includes("locality")
      )?.long_name;
      const state = components?.find((component: any) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;
      const country = components?.find((component: any) =>
        component.types.includes("country")
      )?.long_name;

      return { city, state, country };
    }
    return { city: "", state: "", country: "" };
  };

  useEffect(() => {
    dispatch(fetchLocation());
    dispatch(fetchCurrencyRates());
  }, [dispatch]);

  const onSuggestSelect = (suggest?: Suggest) => {
    if (suggest && suggest.description) {
      const formattedLocation = trimLocation(suggest);
      if (formattedLocation) {
        if (onChange) onChange(formattedLocation);
        else {
          dispatch(setLocation(formattedLocation));
        }
        setShowInput(false);
       setState({ ...state, top: false });
      }
    }
  };

  const selectedValue = useSelector(
    (state: RootState) => state.location.location
  );

  const formattedAddress = value
    ? value
    : [selectedValue?.city, selectedValue?.state, selectedValue?.country]
        .filter(Boolean) // Remove any falsy values (e.g., undefined, null, "")
        .join(", ");

        const [state, setState] = React.useState({
          top: false,
        });
      
        const toggleDrawer =
          (anchor: Anchor, open: boolean) =>
          (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
              event.type === 'keydown' &&
              ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
            ) {
              return;
            }
      
            setState({ ...state, [anchor]: open });
          };
      
        const list = (anchor: Anchor) => (
          <Box
            sx={{ width: anchor === 'top' ? 350 : 800 ,height:"40vh",textAlign:"center",
              alignSelf:"center",marginTop:"10px"
            }}
            role="presentation"
            
           
          >
            <Typography variant="h6" gutterBottom>Select Location</Typography>
          <Geosuggest
            placeholder="Select your location"
            onSuggestSelect={onSuggestSelect}
            initialValue={formattedAddress}
            
            style={{
              
              input: {
                display: "block",
                padding: spacing ? "15px" : "6px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                
              },
            
              suggests: {
              
                color: theme.palette.text.primary,
                
              },
            }}
          />
          </Box>
        );
  return (
                    <div>   
                {([ 'top'] as const).map((anchor) => (
                <React.Fragment key={anchor} >
                    <Button 
                    sx={{ position:"relative",color:"white"}}
                    onClick={toggleDrawer(anchor, true)}>   <LocationOnIcon /></Button>
                    
                    <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    PaperProps={{
                      sx: {
                        borderRadius: '0px 0px 15px 15px', // Adjust the radius here for top drawer (bottom corners rounded)
                      },
                    }}

                    >
                    
                    {list(anchor)}
                    </Drawer>
                </React.Fragment>
                ))}
                </div>
                
              )
                
                

  
};

export default GeosuggestDropdown;










