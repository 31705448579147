import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchWrapper from "../../components/utils/fetchWrapper";
import { showToast } from "./toastSlice";
import { logOutWithClearStorage } from "../../components/utils/helpers";
import { UserProfile } from "../../components/profile/types";
//import Product from "./product.slice";
import { ProductType } from "../../components/products/product.type";
import { checkTokenValidity } from "../../components/auth/Helper";

interface User {
  id: string;
  uuid: string;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  country_code?: string;
  phone_number: string;
  location: {
    city: string;
    state: string;
    country: string;
  };
  profile_picture_url: string;
  last_login: Date;
  registration_date: Date;
  role: string;
  is_exclusive: boolean;
  verification_status: boolean;
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
}

interface AuthState {
  loading: boolean;
  user: {
    isUserLoggedIn: boolean;
    email: string;
  };
  otp: {
    showLogin: boolean;
    isOtpSent: boolean;
  };
  error: string | null;
  users: [];
  userDetails: {
    user: User;
    products: ProductType[];
  };
  currentUserDetails: {
    user: User;
    products: ProductType[];
  };
}

const initialState: AuthState = {
  loading: false,
  user: {
    isUserLoggedIn: localStorage.getItem("isUserLoggedIn") === "true",
    email: localStorage.getItem("email") || "",
  },
  otp: {
    showLogin: false,
    isOtpSent: false,
  },
  error: null,
  users: [],
  userDetails: {
    user: {
      id: "",
      uuid: "",
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      country_code: "",
      phone_number: "",
      location: {
        city: "",
        state: "",
        country: "",
      },
      profile_picture_url: "",
      last_login: new Date(),
      registration_date: new Date(),
      role: "",
      is_exclusive: false,
      verification_status: false,
      is_active: false,
      created_at: new Date(),
      updated_at: new Date(),
    },
    products: [],
  },
  currentUserDetails: {
    user: {
      id: "",
      uuid: "",
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      country_code: "",
      phone_number: "",
      location: {
        city: "",
        state: "",
        country: "",
      },
      profile_picture_url: "",
      last_login: new Date(),
      registration_date: new Date(),
      role: "",
      is_exclusive: false,
      verification_status: false,
      is_active: false,
      created_at: new Date(),
      updated_at: new Date(),
    },
    products: [],
  },
};

export const sendLoginOTP = createAsyncThunk(
  "auth/send-otp",
  async (email: string, thunkAPI) => {
    const apiResponse = await fetchWrapper(
      "auth/send-otp",
      {
        method: "POST",
        body: {
          email,
        },
      },
      true
    );
    thunkAPI.dispatch(
      showToast({
        message: "OTP sent to your email.",
        severity: "success",
      })
    );
    return apiResponse;
  }
);

export const verifyLogin = createAsyncThunk(
  "users/login",
  async (
    reqBody: { email: string; password?: string; idToken?: string },
    thunkAPI
  ) => {
    let apiResponse;

    // Regular login with email and password
    apiResponse = await fetchWrapper(
      "users/login",
      {
        method: "POST",
        body: reqBody,
      },
      true
    );

    if (apiResponse.success) {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "success",
        })
      );
      const token = checkTokenValidity(apiResponse.data.token);
      if (!token.success) {
        logOutWithClearStorage();
        return thunkAPI.rejectWithValue(apiResponse);
      } else {
        thunkAPI.dispatch(setCurrentUserData(token.decodedToken));
      }
      localStorage.setItem("token", apiResponse.data.token);
      localStorage.setItem("email", apiResponse.data.email);
    } else {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(
        apiResponse || "An unexpected error occurred"
      );
    }

    return apiResponse;
  }
);

export const signUp = createAsyncThunk(
  "users/signup",
  async (
    reqBody: {
      email: string;
      first_name: string;
      last_name: string;
      password: string;
      otp: string;
    },
    thunkAPI
  ) => {
    const apiResponse = await fetchWrapper(
      "users/signup",
      {
        method: "POST",
        body: reqBody,
      },
      true
    );
    if (apiResponse.success) {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "success",
        })
      );
      localStorage.setItem("email", reqBody.email);
    } else {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(
        apiResponse || "An unexpected error occurred"
      );
    }
    return apiResponse;
  }
);

export const resetPassword = createAsyncThunk(
  "users/reset-password",
  async (
    reqBody: {
      email: string;
      password: string;
      otp: string;
    },
    thunkAPI
  ) => {
    const apiResponse = await fetchWrapper(
      "users/reset-password",
      {
        method: "POST",
        body: reqBody,
      },
      true
    );

    if (apiResponse.success) {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "success",
        })
      );
      localStorage.setItem("email", reqBody.email);
    } else {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(
        apiResponse || "An unexpected error occurred"
      );
    }
    return apiResponse;
  }
);

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (query: string, thunkAPI) => {
    let apiResponse;

    apiResponse = await fetchWrapper(
      `users${query}`,
      {
        method: "GET",
      },
      true
    );

    if (apiResponse.success) {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "success",
        })
      );
    } else {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(
        apiResponse || "An unexpected error occurred"
      );
    }

    return apiResponse;
  }
);

export const getUserDetails = createAsyncThunk(
  "users/getUserDetails",
  async (email: string, thunkAPI) => {
    const url = email ? `users/${email}` : `users/profile`,
      // Regular login with email and password
      apiResponse = await fetchWrapper(
        url,
        {
          method: "GET",
        },
        true
      );

    if (apiResponse.success) {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "success",
        })
      );
    } else {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(
        apiResponse || "An unexpected error occurred"
      );
    }

    return apiResponse;
  }
);

export const updateUserDetails = createAsyncThunk(
  "users/updateUserDetails",
  async (reqBody: UserProfile, thunkAPI) => {
    let apiResponse;

    // Regular login with email and password
    apiResponse = await fetchWrapper(
      `users/update`,
      {
        body: reqBody,
        method: "PUT",
      },
      true
    );

    if (apiResponse.success) {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "success",
        })
      );
      localStorage.setItem("token", apiResponse.data.token);
    } else {
      thunkAPI.dispatch(
        showToast({
          message: apiResponse.message,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(
        apiResponse || "An unexpected error occurred"
      );
    }

    return apiResponse;
  }
);

const authSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    logout(state) {
      state.otp.isOtpSent = false;
      state.user.isUserLoggedIn = false;
      state.user.email = "";
      state.currentUserDetails = initialState.currentUserDetails;
      logOutWithClearStorage();
    },
    setLoginModelVisibility(state, action: PayloadAction<any>) {
      state.otp.showLogin = action.payload.showLogin;
    },
    setCurrentUserData(state, action: PayloadAction<any>) {
      state.currentUserDetails.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Send Login OTP
    builder
      .addCase(sendLoginOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendLoginOTP.fulfilled, (state) => {
        state.loading = false;
        state.otp.isOtpSent = true;
      })
      .addCase(sendLoginOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.otp.isOtpSent = false;
      });
    // Verify Login OTP
    builder
      .addCase(verifyLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user.email = action.payload.data?.email || "";
        state.user.isUserLoggedIn = true;
        state.otp.showLogin = false;
        localStorage.setItem("isUserLoggedIn", String(true));
      })
      .addCase(verifyLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.user.isUserLoggedIn = false;
      });
    // Get Users
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.users = [];
      });
    // Get User Details
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload?.data?.user?.email === state?.user?.email) {
          state.currentUserDetails = action.payload?.data;
        }
        state.userDetails = {
          ...initialState.userDetails,
          ...action.payload?.data,
        };
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.userDetails = initialState.userDetails;
      });
    // Reset Password
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { logout, setLoginModelVisibility, setCurrentUserData } =
  authSlice.actions;

export default authSlice.reducer;
