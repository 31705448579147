import React from "react";

import { Container, Typography, Box, Divider,Grid,useMediaQuery } from "@mui/material";
import AboutImg from "assets/AboutUs.png"
import Breadcrumb from "../BreadCrumbs";
import { useTheme } from "@mui/material/styles";

const AboutUs: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="md">
      
      <Box position="static" sx={{ padding: theme.spacing(isSmallScreen?2:5) }}></Box>
      <Breadcrumb />
      <Box my={4}>
        <Grid sx={{
           backgroundColor:"#b4f0ee",
           textAlign:"center",
           p:2,
          borderRadius:"10px",
          mb:4
           
        }}>
          <img src={AboutImg} width={50}/>
        <Typography variant="h1" gutterBottom 
        sx={{fontSize: isSmallScreen? "1.2rem":"2rem",
          textAlign:"center",
          color:"black"
        }}
        >
         About Us
        </Typography>
        </Grid>
        
        <Grid>
          <Typography variant="body1">
          <strong>At Bidzbay,</strong>  we connect people and communities, creating endless economic opportunities for everyone. Our platform bridges millions of buyers and sellers worldwide, offering a space where innovation meets commerce, and everyone has the chance to thrive. No matter who you are or where you're from, our cutting-edge technology empowers you to grow, succeed, and make a meaningful impact.
          We believe in more than just transactions—we believe in creating value for our customers, communities, and the planet. Our commitment to fostering real connections drives meaningful change, benefiting not just individuals but also the world around us.

          </Typography>
          <Typography variant="h3" mt={1} fontSize={"1.5rem"}>
            For Buyers
          </Typography>
          <Divider />
          
          <Typography variant="body1">
          Step into a world of discovery with Bidzbay, where unique finds and endless variety await. Whether you shop through our website, regional platforms, or mobile app, you’ll enjoy a personalized shopping experience with access to an exceptional selection—all at unbeatable value.
          </Typography>
          <Typography variant="h3" mt={1} fontSize={"1.5rem"}>
            For Sellers
          </Typography>
          <Divider />
          <Typography variant="body1">
          Unlock your potential and grow your business with Bidzbay. We provide sellers of all sizes and backgrounds with the tools to expand their reach, without barriers or limits. At Bidzbay, we don't compete with our sellers—we support them. Your success is our success, and we’re here to help you achieve it.
          </Typography>

        </Grid>
       
        </Box>
    </Container>
  );
};

export default AboutUs;
