export const TOAST_MESSAGES = {
    CATEGORY: {
        FETCH_CATEGORY: "Oops! Something went wrong while fetching category. Please retry.",
    },
    PRODUCT: {
        FETCH_PRODUCT_ERROR: "Oops! Something went wrong while fetching products. Please retry.",

        ADD_PRODUCT_SUCCESS: "The product has been successfully created.",
        ADD_PRODUCT_ERROR: "Oops! Something went wrong while creating product. Please retry.",
        
        UPDATE_PRODUCT_SUCCESS: "The product has been successfully updated.",
        UPDATE_PRODUCT_ERROR: "Oops! Something went wrong while updating product. Please retry.",

        EXCLUSIVE_PRODUCT_SUCCESS: "The exclusive product has been successfully updated.",
        EXCLUSIVE_PRODUCT_ERROR: "Oops! Something went wrong while updating exclusive product. Please retry.",
        EXCLUSIVE_PRODUCT_FETCH_ERROR: "Oops! Something went wrong while fetching exclusive product. Please retry.",
    },
    CURRENCY: {
        FETCH_CURRENCY: "Oops! Something went wrong while fetching currency. Please retry.",
    },
    LOCATION: {
        FETCH_LOCATION: "Oops! Something went wrong while fetching location. Please retry.",
    },
    BIDS: {
        FETCH_BIDS_ERROR: "Oops! Something went wrong while fetching bids. Please retry.",

        ADD_BIDS_SUCCESS: "The bid has been placed successfully.",
        ADD_BIDS_ERROR: "Oops! Something went wrong while placing bid. Please retry.",

        ACCEPT_BIDS_SUCCESS: "The bid has been accepted successfully.",
        ACCEPT_BIDS_ERROR: "Oops! Something went wrong while accepting bid. Please retry.",

        DELETE_BIDS_SUCCESS: "The bid has been deleted successfully.",
        DELETE_BIDS_ERROR: "Oops! Something went wrong while deleting bid. Please retry.",
    },
    GENERAL_ERROR: "Something went wrong, please try again.",
    NETWORK_ERROR: "Network error, please check your internet connection.",
    UNAUTHORIZED: "You are not authorized to perform this action.",
    NOT_FOUND: "Requested resource not found.",
    FORM_VALIDATION_ERROR: "Please correct the errors in the form.",
    SERVER_ERROR: "Internal server error, please try again later.",
    TIMEOUT_ERROR: "The request timed out, please try again.",
};
