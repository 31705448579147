import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Alert,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/slices/auth.slice";
import { AppDispatch } from "../../store/store";

const ForgotPasswordDialog: React.FC<{
  handleVerifyEmail: (param: string) => void;
  setForgotPassword: (param: boolean) => void;
  closeLoginDialog: () => void;
}> = ({ handleVerifyEmail, setForgotPassword, closeLoginDialog }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ password: '' });
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSendOtp = () => {
    setIsOtpSent(true);
    handleVerifyEmail(email);
  };

  const clearForm = () => {
    setEmail("");
    setOtp("");
    setNewPassword("");
    setError("");
  };

  const handleResetPassword = async () => {
    try {
      const resultAction = await dispatch(
        resetPassword({
          email,
          password: newPassword,
          otp,
        })
      );

      if (resultAction && resultAction?.payload?.success) {
        handleClose();
        clearForm();
      } else {
        setError(resultAction.payload.message || "");
        console.error("Error:", resultAction.payload.message);
      }
    } catch (error) {
      setError("Unexpected server error");
      console.error("Unexpected error:", error);
    }

    // Additional logic to handle password reset
  };

  const handleBack = () => {
    if (isOtpSent) {
      setIsOtpSent(false);
    } else {
      setForgotPassword(false);
    }
    clearForm();
  };

  const handleClose = () => {
    setForgotPassword(false);
    closeLoginDialog();
    clearForm();
  };

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="xs" fullWidth>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
         
          p: 1,
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleBack}
          sx={{ ml: 1 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <DialogTitle variant="h5" align="center" sx={{ flexGrow: 1, ml: -4 }}>
          Reset Your Password
        </DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{ mr: 1 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {error.length > 0 && (
        <Alert severity="error">
          There was an error, please try again: {error}
        </Alert>
      )}
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: "16px" }}
        />
        {!isOtpSent && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSendOtp}
            sx={{ marginBottom: "16px" }}
          >
            Send OTP
          </Button>
        )}

        {isOtpSent && (
          <>
            <TextField
              label="OTP"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              sx={{ marginBottom: "16px" }}
            />
            <Typography sx={{fontSize:"0.7rem",textAlign:"right",
              mt:-1.5,color:"black"
            }} >OTP Valid For 5 Min Only</Typography>

              <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword
                              }
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleResetPassword}
              sx={{ marginTop: "16px" }}
            >
              Reset Password
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
