export interface ProductType {
  uuid: string;
  title: string;
  description: string;
  currency?: string;
  price: number;
  location: {
    city: string;
    state: string;
    country: string;
  };
  bidEndDate?: Date;
  images: string[];
  seller: ISeller;
  category: ICategory;
  subcategory?: ICategory;
  specifications: IProperty;
  isFormValid: boolean;
  status: boolean;
  name: string;
  tags: string[];
  developer: string;
  postedAt: Date;
  highestBid?: {
    currency: string;
    amount: number;
    bidId: string;
    userId: string;
  };
  buyer?: {
    userId: string;
    amount: number;
    bidId: string;
  };
  state: string;
  soldDate?: Date;
}

export type ICategory = {
  uuid: string;
  name: string;
  displayName?: string;
  displayImage?: string;
};

export type ISeller = {
  id: string;
  username: string;
  email: string;
  phone?: string;
  address?: string;
};

export type IProperty = {
  [key: string]: string | number | boolean | any;
  // type: string;
  // bedrooms: string;
  // bathrooms: string;
  // furnishing: string;
};

export type IElectronics = {};

export type BaseProducts = {
  title: string;
  description: string;
  currency: string;
  price: number;
  phone_number: string;
  location: { city: string; state: string; country: string };
  address: any;
  images: File[];
  tags?: [];
  bidEndDate: string;
  category: ICategory;
  subcategory?: ICategory;
  specifications: IProperty;
  isFormValid: boolean;
  status: boolean;
  name: string;
  developer: string;
  removedImages?: [];
};

export const PRODUCT_FORM_STATE: BaseProducts = {
  title: "",
  description: "",
  currency: localStorage.getItem("currency") || "USD",
  price: 0,
  location: { city: "", state: "", country: "" },
  phone_number: "",
  address: {
    location: { city: "", state: "", country: "" },
    fullAddress: "",
    lat: 0,
    lng: 0,
  },
  images: [],
  tags: [],
  bidEndDate: new Date(
    new Date().setDate(new Date().getDate() + 15)
  ).toISOString(),
  category: {
    uuid: "",
    name: "",
  },
  subcategory: {
    uuid: "",
    name: "",
  },
  specifications: {
    type: "",
    bedrooms: "",
    bathrooms: "",
    furnishing: "",
  },
  isFormValid: false,
  status: true,
  name: "",
  developer: "",
  removedImages: [],
};
