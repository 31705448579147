import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import AllCategory from "../category/CategoryAll";
import logo from "assets/logo512.png";



export default function MobileMenu() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
      setOpen(newOpen);
    };
  
    const DrawerList = (
      
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      
       <img style={{position:"absolute",top:"2",height:"7vh",
       margin:"auto",marginLeft:"40%"}} src={logo}/>
        <Divider />
       <AllCategory/>
      </Box>
    );

 
  return (

    <div>
      <Button sx={{color:"white"}} onClick={toggleDrawer(true)}> <MenuIcon/> </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>

    
    
  );
}