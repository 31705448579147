import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface BackgroundVideoProps {
  src: string;
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ src }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  // Lazy load video when it enters the viewport
  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const rect = videoRef.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setIsVideoLoaded(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: isSmallScreen ? "45vh" : isBigScreen ? "70vh" : "35vh",
        overflow: "hidden",
        zIndex: -1,
      }}
    >
      <video
        ref={videoRef}
        autoPlay={isVideoLoaded}
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          top: "-20%",
          left: "0%",
          objectFit: "cover",
          minWidth: "100%",
          minHeight: "100%",
          width: "100vw",
          height: "100vh",
          zIndex: -1,
        }}
        poster="../../assets/main.png"
      >
        {isVideoLoaded && <source src={src} type="video/mp4" />}
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default BackgroundVideo;
