// countryvalues.ts
export const countryCodes = [
  { label: "Austria", value: "+43" },
  { label: "Bahrain", value: "+973" },
  { label: "Bangladesh", value: "+880" },
  { label: "Belgium", value: "+32" },
  { label: "Bulgaria", value: "+359" },
  { label: "Croatia", value: "+385" },
  { label: "Cyprus", value: "+357" },
  { label: "Czech Republic", value: "+420" },
  { label: "Denmark", value: "+45" },
  { label: "Estonia", value: "+372" },
  { label: "Finland", value: "+358" },
  { label: "France", value: "+33" },
  { label: "Germany", value: "+49" },
  { label: "Greece", value: "+30" },
  { label: "Hungary", value: "+36" },
  { label: "Iceland", value: "+354" },
  { label: "India", value: "+91" },
  { label: "Iraq", value: "+964" },
  { label: "Ireland", value: "+353" },
  { label: "Italy", value: "+39" },
  { label: "Kuwait", value: "+965" },
  { label: "Latvia", value: "+371" },
  { label: "Liechtenstein", value: "+423" },
  { label: "Lithuania", value: "+370" },
  { label: "Luxembourg", value: "+352" },
  { label: "Malta", value: "+356" },
  { label: "Monaco", value: "+377" },
  { label: "Montenegro", value: "+382" },
  { label: "Netherlands", value: "+31" },
  { label: "North Macedonia", value: "+389" },
  { label: "Norway", value: "+47" },
  { label: "Oman", value: "+968" },
  { label: "Poland", value: "+48" },
  { label: "Portugal", value: "+351" },
  { label: "Qatar", value: "+974" },
  { label: "Romania", value: "+40" },
  { label: "Russia", value: "+7" },
  { label: "San Marino", value: "+378" },
  { label: "Saudi Arabia", value: "+966" },
  { label: "Serbia", value: "+381" },
  { label: "Sweden", value: "+46" },
  { label: "United Arab Emirates", value: "+971" },
  { label: "United Kingdom", value: "+44" },
  { label: "United States", value: "+1" },
];
