import React, { useState } from "react";
import "./ProductListcss.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import noImage from "../../assets/Noimage.jpg";
import { Box } from "@mui/material";
import { getMediaType } from "../utils/helpers";
import { useTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import {
  Chip,
} from "@mui/material";
import {
  useMediaQuery,
} from "@mui/material";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
  },
  '& .MuiDialogActions-root': {
  
  },
}));

const Carousel = ({ images = [], isSold = false, tag="" }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const data = images?.length
    ? images.map((image: string, index: number) => {
        return {
          src: image,
          alt: `Image ${index}`,
          type: getMediaType(image),
        };
      })
    : [
        {
          src: noImage,
          alt: "NO Image",
          type: "image",
        },
      ];

  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  const [carousel, setStyle] = useState("carousel");
  const [open, setOpen] = useState(false);

  const changeStyle = () => {

    handleClickOpen()
    // setStyle(carousel !== "carousel" ? "carousel" : isSmallScreen? "carousel": isBigScreen? "carouselhover" : "carousel");
  };

  const [slideBlr, setslideBlr] = useState("slide");

  // State for the indicator slide index
  const [indicatorSlide, setIndicatorSlide] = useState(0);

  const indicatorsPerPage = 6;
  const indicatorsPerPageZoom = isSmallScreen? 3 : 10; // Updated to handle 6 indicators per page

  const maxIndicatorSlide = Math.floor((data.length - 1) / indicatorsPerPage);

  const nextIndicatorSlide = () => {
    setIndicatorSlide(
      indicatorSlide === maxIndicatorSlide ? 0 : indicatorSlide + 1
    );
  };
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const prevIndicatorSlide = () => {
    setIndicatorSlide(
      indicatorSlide === 0 ? maxIndicatorSlide : indicatorSlide - 1
    );
  };
  console.log("isSold", isSold);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        fullScreen
        aria-labelledby="customized-dialog-title"
        open={open}
       
      >
       
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: "white",
            zIndex:2
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{alignContent:"center",backgroundColor: "rgba(0, 0, 0, 0.9)"}}>
        <div className="slides" style={{marginTop: isSmallScreen? "0":"10px"}} >
       
       <div className="carouselZoom" style={{marginBottom:"10px"}} >
 
         {tag[1]&& 
               <Chip
                   label="Exclusive Product"
                   color="primary"
                 
                   sx={{ background:orange[800],
                     position: "absolute",
                       top: 10, 
                       left: 0,
                       fontSize: isSmallScreen? "0.7rem":"1rem",
                       borderRadius:"0px",
                      
                     
                     }}
                 />
         }
         {data.length < 2 ? (
           ""
         ) : (
           <KeyboardArrowLeftIcon
             onClick={prevSlide}
             className="arrow arrow-left"
           />
         )}
         {isSold && (
          
               <Box
             sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%) rotate(-40deg)", // Center and rotate
               backgroundColor: "rgba(92, 234, 210, 1)", // Red semi-transparent background
               color: "white",
               fontSize: "3rem",
               fontWeight: "bold",
               padding: "10px 30px",
               borderRadius: "5px",
               boxShadow:"1px 1px 2px 1px black",
               zIndex: 2, // Ensure it's on top of the image
               textAlign: "center",
             }}
           >
             SOLD
           </Box>
 
           
         
         )}
         {data.map((item: any, idx: number) => {
           return (
             <>
               {item.type === "image" && (
                 <img
                   src={item.src}
                   alt={item.alt}
                   key={idx}
                   className={slide === idx ? isSold? "slideBlur":"slide" : "slide slide-hidden"}
                   onClick={changeStyle}
                  style={{height: isSmallScreen? "50vh": "75vh"}}
                 />
               )}
               {item.type === "video" && (
                 <video
                   key={idx}
                   autoPlay
                   muted
                   loop
                   controls
                   className={slide === idx ? "slide" : "slide slide-hidden"}
                   style={{
                     objectFit: "cover",
                     minWidth: "100%",
                     minHeight: "100%",
                     maxHeight: "415px",
                   }}
                   onClick={changeStyle}
                 >
                   <source src={item.src} type="video/mp4" />
                   Your browser does not support the video tag.
                 </video>
               )}
             </>
           );
         })}
 
         {data.length < 2 ? (
           ""
         ) : (
           <KeyboardArrowRightIcon
             onClick={nextSlide}
             className="arrow arrow-right"
           />
         )}
       </div>
 
       {data.length > 1 && (
         <div className="indicators-container">
           <div className="indicators" >
             <div className="indicator-arrow-indicator-arrow-leftt">
               {data.length > indicatorsPerPageZoom && (
                 <KeyboardArrowLeftIcon onClick={prevIndicatorSlide} />
               )}
             </div>
             {data
               .slice(
                 indicatorSlide * indicatorsPerPageZoom,
                 indicatorSlide * indicatorsPerPageZoom + indicatorsPerPageZoom
               )
               .map((item: any, idx: number) => {
                 return (
                   <>
                     {item.type === "image" && (
                       <img
                         src={item.src}
                         alt={item.alt}
                         style={{margin:"0px"}}
                         key={indicatorSlide * indicatorsPerPageZoom + idx}
                         className={
                           slide === indicatorSlide * indicatorsPerPageZoom + idx
                             ? "indicatorZoom"
                             : "indicatorZoom indicator-inactive"
                         }
                         onClick={() =>
                           setSlide(indicatorSlide * indicatorsPerPageZoom + idx)
                         }
                       />
                     )}
                     {item.type === "video" && (
                       <video
                         autoPlay={false}
                         muted
                         loop
                         controls={false}
                         style={{
                            margin:"0px",
                           objectFit: "cover",
                         }}
                         className={
                           slide === indicatorSlide * indicatorsPerPageZoom + idx
                             ? "indicatorZoom"
                             : "indicatorZoom indicator-inactive"
                         }
                         onClick={() =>
                           setSlide(indicatorSlide * indicatorsPerPageZoom + idx)
                         }
                       >
                         <source src={item.src} type="video/mp4" />
                         Your browser does not support the video tag.
                       </video>
                     )}{" "}
                   </>
                 );
               })}
             <div className="indicator-arrow-indicator-arrow-rightt">
               {data.length > indicatorsPerPageZoom && (
                 <KeyboardArrowRightIcon onClick={nextIndicatorSlide} />
               )}
             </div>
           </div>
         </div>
       )}
     </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
    <div className="slides">
       
      <div className={carousel}>

        {tag[1]&& 
              <Chip
                  label="Exclusive Product"
                  color="primary"
                
                  sx={{ background:orange[800],
                    position: "absolute",
                      top: 10, 
                      left: 0,
                      fontSize: isSmallScreen? "0.7rem":"1rem",
                      borderRadius:"0px",
                     
                    
                    }}
                />
        }
        {data.length < 2 ? (
          ""
        ) : (
          <KeyboardArrowLeftIcon
            onClick={prevSlide}
            className="arrow arrow-left"
          />
        )}
        {isSold && (
         
              <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) rotate(-40deg)", // Center and rotate
              backgroundColor: "rgba(92, 234, 210, 1)", // Red semi-transparent background
              color: "white",
              fontSize: "3rem",
              fontWeight: "bold",
              padding: "10px 30px",
              borderRadius: "5px",
              boxShadow:"1px 1px 2px 1px black",
              zIndex: 2, // Ensure it's on top of the image
              textAlign: "center",
            }}
          >
            SOLD
          </Box>

          
        
        )}
        {data.map((item: any, idx: number) => {
          return (
            <>
              {item.type === "image" && (
                <img
                  src={item.src}
                  alt={item.alt}
                  key={idx}
                  className={slide === idx ? isSold? "slideBlur":"slide" : "slide slide-hidden"}
                  onClick={changeStyle}
                  style={{ minHeight: isSmallScreen? "210px": isBigScreen? "425px":"240px",
                    maxHeight: isSmallScreen? "210px": isBigScreen? "425px":"240px"
                  }}
                />
              )}
              {item.type === "video" && (
                <video
                  key={idx}
                  autoPlay
                  muted
                  loop
                  controls
                  className={slide === idx ? "slide" : "slide slide-hidden"}
                  style={{
                    objectFit: "cover",
                    minWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "415px",
                  }}
                  onClick={changeStyle}
                >
                  <source src={item.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </>
          );
        })}

        {data.length < 2 ? (
          ""
        ) : (
          <KeyboardArrowRightIcon
            onClick={nextSlide}
            className="arrow arrow-right"
          />
        )}
      </div>

      {data.length > 1 && (
        <div className="indicators-container">
          <div className="indicators">
            <div className="indicator-arrow-indicator-arrow-left">
              {data.length > indicatorsPerPage && (
                <KeyboardArrowLeftIcon onClick={prevIndicatorSlide} />
              )}
            </div>
            {data
              .slice(
                indicatorSlide * indicatorsPerPage,
                indicatorSlide * indicatorsPerPage + indicatorsPerPage
              )
              .map((item: any, idx: number) => {
                return (
                  <>
                    {item.type === "image" && (
                      <img
                        src={item.src}
                        alt={item.alt}
                        key={indicatorSlide * indicatorsPerPage + idx}
                        className={
                          slide === indicatorSlide * indicatorsPerPage + idx
                            ? "indicator"
                            : "indicator indicator-inactive"
                        }
                        onClick={() =>
                          setSlide(indicatorSlide * indicatorsPerPage + idx)
                        }
                      />
                    )}
                    {item.type === "video" && (
                      <video
                        autoPlay={false}
                        muted
                        loop
                        controls={false}
                        style={{
                          objectFit: "cover",
                        }}
                        className={
                          slide === indicatorSlide * indicatorsPerPage + idx
                            ? "indicator"
                            : "indicator indicator-inactive"
                        }
                        onClick={() =>
                          setSlide(indicatorSlide * indicatorsPerPage + idx)
                        }
                      >
                        <source src={item.src} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}{" "}
                  </>
                );
              })}
            <div className="indicator-arrow-indicator-arrow-right">
              {data.length > indicatorsPerPage && (
                <KeyboardArrowRightIcon onClick={nextIndicatorSlide} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default Carousel;
