import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Home from "../../assets/home.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getCategoryTags } from "../../store/slices/category.slice";
import { ICategory } from "../../components/products/product.type";
import { updateNestedValue } from "../../components/utils/helpers";
import { setFilters } from "../../store/slices/filter.slice";
import CategoryIcon from "@mui/icons-material/Category";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PopularCategories = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const categoriesTags = useSelector((state: RootState) =>
    getCategoryTags(state.category)
  );
  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );

  const handleOnClickPopularCategory = (category: ICategory) => {
    const updatedValues = updateNestedValue(
      { ...selectedFilters },
      "category.uuid",
      category.uuid
    );
    dispatch(setFilters(updatedValues));
    navigate("/products");
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: isSmallScreen ? "1.4rem" : "2rem",
          marginBottom: "15px",
        }}
      >
        Explore Popular Categories
      </Typography>
      <Grid container spacing={2}>
        {categoriesTags.map((category: ICategory) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            key={category.name}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: isSmallScreen ? 80 : 150,
                height: isSmallScreen ? 80 : 150,
                borderRadius: "50%",
                overflow: "hidden",
                marginBottom: 2,
                boxShadow: 3,
                cursor: "pointer",
              }}
              onClick={() => handleOnClickPopularCategory(category)}
            >
              {category.displayImage ? (
                <img
                  src={category.displayImage ? category.displayImage : ""}
                  alt={category.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <CategoryIcon
                  sx={{
                    width: "100%",
                    height: "80%",
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
            <Typography sx={{ fontSize: "0.9rem" }} variant="subtitle1">
              {category.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PopularCategories;
