import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  sendLoginOTP,
  setLoginModelVisibility,
  verifyLogin,
} from "../../store/slices/auth.slice";
import { AccountMenu } from "./Account";
//import { useGoogleLogin } from "./GoogleLoginContext";
import ForgotPasswordDialog from "./ForgotPassword";
import SignUpDialog from "./SignUp";
import Loader from "../Loader";
import { validateEmail } from "../utils/helpers";
import { Link } from "react-router-dom";
//import { jwtDecode } from "jwt-decode";
//import { JwtPayload } from "./Helper";

const LoginDialog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  //const { setGoogleLoginState } = useGoogleLogin();

  const showLoginModel: boolean = useSelector(
    (state: RootState) => state.auth.otp.showLogin
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [isSignUp, setSignUpDialog] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const loading = useSelector((state: RootState) => state.auth.loading);

  const resetForm = () => {
    setError(false);
    setEmail("");
    setPassword("");
    setErrorMessage("");
  };

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ password: '' });

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClose = useCallback(() => {
    dispatch(setLoginModelVisibility(false));
    resetForm();
  }, [dispatch]);

  const handleVerifyEmail = (emailId: string) => {
    dispatch(sendLoginOTP(emailId));
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    const emailError = validateEmail(email);
    if (emailError) newErrors.email = emailError;
    if (!password) newErrors.password = "Password is required";
    if (password.length < 8)
      newErrors.password = "Password must be at least 8 characters";
    if (newErrors.email || newErrors.password) {
      setError(true);
      setErrorMessage(newErrors.email || newErrors.password);
    } else {
      setError(false);
      setErrorMessage("");
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async () => {
    if (validate()) {
      try {
        const result = await dispatch(verifyLogin({ email, password }));

        if (verifyLogin.fulfilled.match(result) && result.payload.success) {
          resetForm();
        } else {
          setError(true);
          setErrorMessage(result.payload?.message || "An error occurred");
        }
      } catch (error) {
        setError(true);
        setErrorMessage("Login failed");
      }
    }
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleCreateAccount = () => {
    setSignUpDialog(true);
  };

  return (
    <>
      {!isForgotPassword && !isSignUp && (
        <Dialog
          open={showLoginModel}
          onClose={handleClose}
          maxWidth="xs"
        
          fullWidth
          sx={{width:"105%",justifySelf:"center"}}
        >
          <DialogTitle variant="h5" align="center">
            Welcome to Bidzbay
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            {error && (
              <Alert severity="error">
                {errorMessage
                  ? errorMessage
                  : "There was an error, please try again"}
              </Alert>
            )}
            {loading && <Loader />}
            {!loading && (
              <>
                <Box sx={{ width: "100%", marginTop: 2 }}>
                  <Typography variant="body1" gutterBottom sx={{ ml: 0 }}>
                    <AccountMenu handleClose={handleClose} />
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ marginTop: 2 }}
                  >
                    Or login with
                  </Typography>
                </Box>

                <>
                  <TextField
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                    
                      marginBottom: "16px",
                      width: "300px",
                      height: "40px",
                    }}
                  />
                   <TextField
                      required
                        margin="normal"
                      sx={{
                        marginBottom: "16px",
                        width: "300px",
                        height: "40px",
                      }}
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!!errors.password}
                      helperText={errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword
                              }
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleLogin()}
                    sx={{
                      marginTop: "16px",
                      width: "300px",
                      height: "40px",
                    }}
                  >
                    Login
                  </Button>
                  <Box sx={{ marginTop: "10px", width: "100%" }}>
                    <Typography
                      variant="body2"
                      color="primary"
                      onClick={handleForgotPassword}
                      sx={{
                        cursor: "pointer",
                        textAlign: "left",
                        display: "inline-block",
                      }}
                    >
                      Forgot Password?
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary"
                      onClick={handleCreateAccount}
                      sx={{
                        cursor: "pointer",
                        textAlign: "right",
                        display: "inline-block",
                        ml: 2,
                      }}
                    >
                      Create Account
                    </Typography>
                  </Box>
                  <Box mt={2} width={"100%"}>
                    <Typography variant="body2" color={"grey"} fontSize={"0.6rem"}>
                    All your personal details are safe with us.
                    </Typography>
                    <Typography variant="body2" color={"grey"} fontSize={"0.7rem"}>
                    If you continue, you are accepting {"\n "} 
                    <a href="/terms-conditions" target="blank">Terms & Conditions</a> And
                    {" "}  <a href="/privacy-policy" target="blank">Privacy Policy</a>.
                    </Typography>
                  </Box>
                </>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
      {isForgotPassword && (
        <ForgotPasswordDialog
          handleVerifyEmail={handleVerifyEmail}
          setForgotPassword={setForgotPassword}
          closeLoginDialog={handleClose}
        />
      )}
      {isSignUp && (
        <SignUpDialog
          handleVerifyEmail={handleVerifyEmail}
          setSignUpDialog={setSignUpDialog}
          closeLoginDialog={handleClose}
        />
      )}
    </>
  );
};

export default LoginDialog;
