// src/components/ContentSection.tsx
import React from "react";
import { Paper, Typography } from "@mui/material";
import SearchBox from "./SearchBar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ContentSection: React.FC = React.memo(() => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "20px",
      }}
    >
      <Typography
        variant="h4"
        sx={{ color: "#fff", fontSize: isSmallScreen ? "1.2rem" : "2.5rem" }}
      >
        Bidding made exciting and easy
      </Typography>
      <SearchBox />
    </Paper>
  );
});

export default ContentSection;
