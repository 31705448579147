import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductType,
  BaseProducts,
  PRODUCT_FORM_STATE,
} from "../../components/products/product.type";

import fetchWrapper from "../../components/utils/fetchWrapper";
import {
  convertToFilterFormat,
  flattenObject,
} from "../../components/utils/helpers";
import { showToast } from "./toastSlice";
import { TOAST_MESSAGES } from "../../components/utils/constants/toastMessages";

interface ProductsState {
  loading: boolean;
  products: ProductType[];
  exclusiveProducts: ProductType[];
  productForm: any;
  error: string | null;
  apiResponse: Record<any, any>;
}

const initialState: ProductsState = {
  loading: false,
  products: [],
  exclusiveProducts: [],
  productForm: PRODUCT_FORM_STATE,
  error: null,
  apiResponse: {},
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      filter: { filters },
      location: { location },
    }: any = getState();
    let { search, filter } = convertToFilterFormat(filters);
    if (!Object.keys(filter).length) {
      const locationFilter = convertToFilterFormat({ ...filters, location: { ...location } });
      filter = locationFilter.filter;
    }
    let filterURLQueryParams: string = "products?";
    if (search) {
      filterURLQueryParams += `search=${search}`;
    }
    if (filter && search) {
      filterURLQueryParams += `&filter=${JSON.stringify(filter)}`;
    } else if (filter) {
      filterURLQueryParams += `filter=${JSON.stringify(filter)}`;
    }
    const { success, data, message } = await fetchWrapper(filterURLQueryParams);
    if (success && data) {
      return data.products as ProductType[];
    }
    dispatch(
      showToast({
        message: TOAST_MESSAGES.PRODUCT.FETCH_PRODUCT_ERROR,
        severity: "error",
      })
    );
    return rejectWithValue(message);
  }
);

export const fetchExclusiveProducts = createAsyncThunk(
  "products/fetchExclusiveProducts",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      filter: { filters },
    }: any = getState();
    let filterParams: any = {
      tags: "Exclusive",
    };
    if (filters.location?.city) {
      filterParams["location.city"] = filters.location?.city;
    }
    if (filters.location?.state) {
      filterParams["location.state"] = filters.location?.state;
    }
    if (filters.location?.country) {
      filterParams["location.country"] = filters.location?.country;
    }
    let filterURLQueryParams: string = `products?filter=${JSON.stringify(
      filterParams
    )}&limit=${10}`;
    const { success, data, message } = await fetchWrapper(filterURLQueryParams);
    if (success && data) {
      return data.products as ProductType[];
    }
    dispatch(
      showToast({
        message: TOAST_MESSAGES.PRODUCT.EXCLUSIVE_PRODUCT_FETCH_ERROR,
        severity: "error",
      })
    );
    return rejectWithValue(message);
  }
);

export const addProducts = createAsyncThunk(
  "products/addProducts",
  async (productData: any, { dispatch, rejectWithValue }) => {
    const formData = new FormData();
    const flattenedFormValues = flattenObject(productData);
    for (let key in flattenedFormValues) {
      if (key !== "isFormValid" && key !== "images") {
        if (flattenedFormValues.hasOwnProperty(key)) {
          formData.append(key, flattenedFormValues[key]);
        }
      }
    }
    if (Array.isArray(productData.images)) {
      productData.images.forEach((image: File, index: number) => {
        formData.append(`images`, image);
      });
    } else {
      formData.append("images", productData.images);
    }

    const response = await fetchWrapper("products", {
      method: "POST",
      body: formData,
      isFormData: true,
    });

    if (response.success) {
      dispatch(setApiResponse({ success: response.success }));
      dispatch(
        showToast({
          message: TOAST_MESSAGES.PRODUCT.ADD_PRODUCT_SUCCESS,
          severity: "success",
        })
      );
      return response.data as ProductType[];
    } else {
      dispatch(setApiResponse(response));
      dispatch(
        showToast({
          message: TOAST_MESSAGES.PRODUCT.ADD_PRODUCT_ERROR,
          severity: "error",
        })
      );
      return [] as ProductType[];
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (productData: any, { dispatch }) => {
    const formData = new FormData();
    const flattenedFormValues = flattenObject(productData);
    for (let key in flattenedFormValues) {
      if (
        key !== "isFormValid" &&
        key !== "images" &&
        key !== "removedImages"
      ) {
        if (flattenedFormValues.hasOwnProperty(key)) {
          formData.append(key, flattenedFormValues[key]);
        }
      }
    }
    if (Array.isArray(productData.images)) {
      productData.images.forEach((image: File, index: number) => {
        formData.append(`images`, image);
      });
    } else {
      formData.append("images", productData.images);
    }

    if (Array.isArray(productData.removedImages)) {
      formData.append(
        `removedImages`,
        JSON.stringify(productData.removedImages)
      );
    }

    const response = await fetchWrapper(`products/${productData.uuid}`, {
      method: "PUT",
      body: formData,
      isFormData: true,
    });

    if (response.success) {
      dispatch(setApiResponse({ success: response.success }));
      dispatch(
        showToast({
          message: TOAST_MESSAGES.PRODUCT.UPDATE_PRODUCT_SUCCESS,
          severity: "success",
        })
      );
      return response.data as ProductType[];
    } else {
      dispatch(setApiResponse(response));
      dispatch(
        showToast({
          message: TOAST_MESSAGES.PRODUCT.UPDATE_PRODUCT_ERROR,
          severity: "error",
        })
      );
      return [] as ProductType[];
    }
  }
);

export const updateExclusiveProduct = createAsyncThunk(
  "products/updateExclusiveProduct",
  async (productData: any, { dispatch }) => {
    const response = await fetchWrapper(
      `products/updateExclusiveProduct/${productData.uuid}`,
      {
        method: "PUT",
        body: { isExclusive: !!productData.isExclusive },
      }
    );

    if (response.success) {
      dispatch(setApiResponse({ success: response.success }));
      dispatch(
        showToast({
          message: TOAST_MESSAGES.PRODUCT.EXCLUSIVE_PRODUCT_SUCCESS,
          severity: "success",
        })
      );
      return response.data as ProductType[];
    } else {
      dispatch(setApiResponse(response));
      dispatch(
        showToast({
          message: TOAST_MESSAGES.PRODUCT.EXCLUSIVE_PRODUCT_ERROR,
          severity: "error",
        })
      );
      return [] as ProductType[];
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProductForm(state, action: PayloadAction<BaseProducts>) {
      state.productForm = action.payload;
    },
    resetProductForm(state) {
      state.productForm = PRODUCT_FORM_STATE;
    },
    setApiResponse(state, action: PayloadAction<any>) {
      state.apiResponse = action.payload;
    },
    clearApiResponse(state) {
      state.apiResponse = {};
    },
  },
  extraReducers: (builder) => {
    // fetchProducts
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchProducts.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
          state.products = action.payload;
          state.apiResponse = { success: false };
        }
      )
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || TOAST_MESSAGES.GENERAL_ERROR;
        state.apiResponse = { success: false };
      });

    // addProducts
    builder
      .addCase(addProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addProducts.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
          state.products.push(action.payload as any);
          state.productForm = PRODUCT_FORM_STATE;
        }
      )
      .addCase(addProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || TOAST_MESSAGES.GENERAL_ERROR;
      });

    // fetchExclusiveProducts
    builder
      .addCase(fetchExclusiveProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchExclusiveProducts.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
          state.exclusiveProducts = action.payload;
        }
      )
      .addCase(fetchExclusiveProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || TOAST_MESSAGES.GENERAL_ERROR;
      });

    // updateProduct
    builder
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateProduct.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
        }
      )
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || TOAST_MESSAGES.GENERAL_ERROR;
      });
    // updateExclusiveProduct
    builder
      .addCase(updateExclusiveProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateExclusiveProduct.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
        }
      )
      .addCase(updateExclusiveProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || TOAST_MESSAGES.GENERAL_ERROR;
      });
  },
});

export const { setProductForm, resetProductForm, setApiResponse } =
  productsSlice.actions;

export const getProduct = (state: ProductsState) => {
  return state.products;
};
export default productsSlice.reducer;
