import * as React from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ProductType } from "../product.type";
import { Bid } from "../BidCard";
import { currencySymbols } from "../../utils/constants/constants";
import CurrencyPrice from "../../common/PriceWithCurrency";
import { PhoneNumber } from "../../common/PhoneNumber";

interface BidModalProps {
  product: ProductType;
  open: boolean;
  onClose: () => void;
  handleAmountChange: (e: any) => void;
  setCurrency: (currency: any) => void;
  handleSubmit: () => void;

  topBid: number;
  currency: string;
  topBidCurrent: string;
  myBid: Bid | null;
  error: any;
  checked?: boolean;
  phone_number: string;
  setConsent?: (checked: boolean) => void;
  handleNumberChange: (value: string) => void;
}

const BidDialog: React.FC<BidModalProps> = ({
  open = false,
  onClose,
  topBid,
  product,
  myBid,
  currency = localStorage.getItem("currency") || "USD",
  topBidCurrent,
  error,
  phone_number,
  handleAmountChange,
  handleNumberChange,
  handleSubmit,
  setCurrency,
  checked = true,
  setConsent = (checked: boolean) => {},
}) => {
  return (
    <Dialog
      sx={{ p: 2, lineHeight: 5 }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
        Place Your Bid
      </DialogTitle>
      <DialogContent>
        {topBid > 0 && (
          <Typography variant="subtitle1">
            Top Bidding amount is&nbsp;&nbsp;
            <Typography variant="h6" color="textPrimary" display="inline-flex">
              <CurrencyPrice
                price={topBid}
                currency={
                  topBidCurrent || localStorage.getItem("currency") || "USD"
                }
              />
            </Typography>
          </Typography>
        )}
        <Typography variant="subtitle1">
          Minimum Bidding amount is&nbsp;&nbsp;
          <Typography variant="h6" color="textPrimary" display="inline-flex">
            <CurrencyPrice
              price={product?.price}
              currency={
                product?.currency || localStorage.getItem("currency") || "USD"
              }
            />
          </Typography>
        </Typography>
        <Typography variant="subtitle2">
          Please enter higher than top bid amount to get more probability of
          getting this product. Wish you a good luck.
        </Typography>
        {/* <Select
          labelId="currency-label"
          id="currency"
          name="currency"
          value={currency || "USD"}
          onChange={setCurrency}
          label="Select Currency"
          fullWidth
        >
          {Object.entries(COUNTRY_CURRENCY).map(([currency, val], index) => (
            <MenuItem value={val.toUpperCase()} key={`${currency}-${index}`}>
              {val.toUpperCase()}
            </MenuItem>
          ))}
        </Select> */}
        <TextField
          id="outlined-basic"
          label="Enter Amount"
          variant="outlined"
          name="bidAmount"
          type="number"
          onChange={handleAmountChange}
          sx={{ mt: 3, width: "100%", mb: 3 }}
          error={!!error.bidAmount}
          helperText={error.bidAmount}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currencySymbols[currency] || currency}
              </InputAdornment>
            ),
          }}
        />
        {/* <TextField
          fullWidth
          type="tel"
          label="Phone Number"
          name="phone_number"
          required
          sx={{ mt: 3, width: "100%" }}
          value={phone_number || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length <= 15) {
              handleNumberChange(e.target.value);
            }
          }}
          error={!!error.phone_number}
          helperText={
            error.phone_number ||
            "Please enter a phone number with country code like +919988776655"
          }
        /> */}
        <PhoneNumber onChange={handleNumberChange} number={phone_number} />
        <FormHelperText sx={{ color: "red" }}>
          {error.phone_number}
        </FormHelperText>
        <input
          type="checkbox"
          name="showConfirm"
          checked={checked} // Use checked instead of value
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConsent && setConsent(e.target.checked)
          } // Handle checkbox change
        />{" "}
        I consent to sharing my phone number with the seller if my bid accepted.
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" autoFocus>
          {myBid && myBid.uuid ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BidDialog;
