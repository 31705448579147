import { jwtDecode } from "jwt-decode";

export interface JwtPayload {
  given_name: string;
  family_name: string;
  name: string;
  email: string;
  first_name?: string;
  last_name?: string;
  exp: number;
  iat: number;
}

export const setUserData = (googleUser: JwtPayload) => {
  const firstName = googleUser?.given_name;
  const lastName = googleUser?.family_name;

  const userData = {
    name: googleUser?.name,
    emailId: googleUser?.email,
    firstName,
    lastName,
  };

  localStorage.setItem("userdata", JSON.stringify(userData));
  return {
    isAuthenticated: true,
    userData,
  };
};

export function checkTokenValidity(token: string | null): {
  success: boolean;
  decodedToken: JwtPayload | "Invalid Token";
} {
  if (!token)
    return {
      success: false,
      decodedToken: "Invalid Token",
    };

  try {
    const decoded: JwtPayload = jwtDecode(token);

    // Get current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token has expired
    if (decoded.exp < currentTime) {
      console.log("Token has expired");
      return {
        success: false,
        decodedToken: "Invalid Token",
      };
    }

    localStorage.setItem("isUserLoggedIn", "true");

    return {
      success: true,
      decodedToken: decoded,
    }; // Token is valid
  } catch (error) {
    console.error("Invalid token:", error);
    localStorage.setItem("isUserLoggedIn", "false");
    localStorage.setItem("token", "");
    return {
      success: false,
      decodedToken: "Invalid Token",
    }; // Invalid token format
  }
}
