import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  description: string;
  showConfirm?: boolean;
  checked?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  setConsent?: (checked: boolean) => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  description,
  showConfirm = false,
  checked = true,
  onCancel,
  onConfirm,
  setConsent = (checked: boolean) => {},
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {showConfirm && (
          <DialogContentText>
            <input
              type="checkbox"
              name="showConfirm"
              checked={checked} // Use checked instead of value
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConsent && setConsent(e.target.checked)
              } // Handle checkbox change
            />{" "}
            I consent to sharing my phone number with the buyer.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
