import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  expired: boolean;
}

interface BiddingTimerProps {
  bidEndDate: Date;
  isBidClosed: boolean;
}

const BiddingTimer: React.FC<BiddingTimerProps> = ({
  bidEndDate,
  isBidClosed,
}) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    expired: false,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const endDate = new Date(bidEndDate).getTime();
      const now = new Date().getTime();
      const difference = endDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
          expired: false,
        });
      } else {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          expired: true,
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [bidEndDate]);

  return (
    <>
      <Typography variant="h6" sx={{ color: "black" }}>
        Time Left:
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "black",
          fontSize: "1rem",
          background: isBidClosed ? "rgba(92, 234, 210, 1)" : "#D3D3D3",
          fontWeight: "bold",
          padding: "5px",
          textAlign: "center",
        }}
      >
        {isBidClosed
          ? "This Deal is closed now"
          : timeLeft.expired
          ? "This Deal is expired"
          : `${timeLeft.days}d | ${timeLeft.hours}h : ${timeLeft.minutes}m :
              ${timeLeft.seconds}s`}
      </Typography>
    </>
  );
};

export default BiddingTimer;
