import fetchWrapper, {
  RequestOptions,
} from "../../components/utils/fetchWrapper";

export const fetchProductData = async (queryParams: {}) => {
  return fetchWrapper("products?filter=" + JSON.stringify(queryParams))
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};

export const fetchProductDataForDownload = async (queryParams: {}) => {
  return fetchWrapper("products/download?filter=" + JSON.stringify(queryParams))
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};

export const fetchSingleProductData = async (
  uuid: string,
  includeSimilarProducts: boolean = false
) => {
  return fetchWrapper(
    `products/${uuid}?includeSimilarProducts=${includeSimilarProducts}`
  )
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};

export const changeStatusProductById = async (
  productId: string,
  state: string
) => {
  const options: RequestOptions = {
    method: "PUT",
    body: { state },
  };

  return fetchWrapper(`products/state/${productId}`, options)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};

export const filterLocation = (location: any) =>
  [location?.city, location?.state, location?.country]
    .filter(
      (part) =>
        part !== undefined &&
        part !== null &&
        part !== "" &&
        part !== "undefined"
    ) // Remove any falsy values (e.g., undefined, null, "")
    .join(", ");
