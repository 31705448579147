import { MenuItem, SelectChangeEvent, FormControl, InputLabel, Select } from "@mui/material";
import React from "react";

type IOptions = {
  label: string;
  value: string;
};

type SelectDropdownProps = {
  label: string;
  name: string;
  value?: string;
  placeHolder?: string;
  sxOptions?: any;
  options: IOptions[];
  onChange: (event: SelectChangeEvent<string>) => void;
  size?: "small" | "medium"
};

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  label = "Select",
  name,
  value = "",
  placeHolder = "",
  sxOptions,
  options,
  onChange,
  size = "small"
}) => {
  return (
    <FormControl sx={sxOptions} size={size}>
    
      <InputLabel sx={{backgroundColor:"white",
        borderTopRightRadius:"5px"
        ,borderTopLeftRadius:"5px",
        padding:"3px",
        pt:0,
      
      }
        }>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        placeholder="hello"
      >
     
        {options.map((item: IOptions) => (
          <MenuItem key={item.value} value={item.value}>
            
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
