import React from "react";
import { Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ProductType } from "../products/product.type";
import Product from "../products/Product";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

type SimilarProductsProps = {
  similarProducts?: ProductType[];
};

const SimilarProducts: React.FC<SimilarProductsProps> = ({
  similarProducts = [],
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        mb:1,
        color: theme.palette.text.primary,
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            m: "25px 0px 20px",
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: isSmallScreen ? "center" : "space-between",
            alignItems: isSmallScreen ? "center" : "flex-start",
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          Similar Products
          <Button
            variant="outlined"
            color="primary"
            sx={{
              alignSelf: isSmallScreen ? "right" : "flex-start",
              mt: isSmallScreen ? 2 : 0,
              width: isSmallScreen ? "100%" : "auto",
            }}
            onClick={() => navigate("/products")}
          >
            More Similar Products
          </Button>
        </Typography>
        <Grid container spacing={1} style={{}}>
          {similarProducts.map((product, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={product?.title + "_" + index}
              onClick={() => navigate(`/products/${product.uuid}`)}
            >
              <Product product={product} showButtons={false} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SimilarProducts;
