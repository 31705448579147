type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

// const AUTH_REACT_APP_API_URL = "http://localhost:3001/";
//const REACT_APP_API_URL = "http://localhost:3002/";
// const AUTH_REACT_APP_API_URL = "https://auth.bidzbay.com/";
// const REACT_APP_API_URL = "https://api.bidzbay.com/";

export interface RequestOptions {
  method?: RequestMethod;
  headers?: HeadersInit;
  body?: any;
  isFormData?: boolean;
}

const fetchWrapper = async (
  endpoint: string,
  options: RequestOptions = {},
  isAuthUrl: boolean = false
) => {
  const { method = "GET", headers = {}, body, isFormData = false } = options;
  let headersObj = {};
  const token = localStorage.getItem("token");
  if (isFormData) {
    headersObj = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      body,
    };
  } else {
    headersObj = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        ...headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    };
  }
  const config: RequestInit = headersObj;

  try {
    let baseURL: string = process.env.REACT_APP_API_URL || "";
    if (isAuthUrl) {
      baseURL = process.env.REACT_APP_API_BASE_URL_USER || "";
    }
    const response = await fetch(`${baseURL}${endpoint}`, config);

    if (!response.ok || !response.status) {
      const errorData = await response.json();
      // throw new Error(errorData.message || "Something went wrong");
      return {
        ...errorData,
        success: false,
      };
    }

    const responseJSON = await response.json();
    if (!responseJSON.success) {
      if (responseJSON.status === 401 && endpoint !== "users/login") {
        localStorage.clear();
        window.location.href = "/";
        return {
          success: false,
        };
      }
      return {
        ...responseJSON,
        success: false,
      };
    } else {
      return {
        ...responseJSON,
        success: true,
      };
    }
  } catch (error) {
    console.error("Fetch error:", error);
    return {
      error,
      success: false,
    };
  }
};

export default fetchWrapper;
