import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface ModalProps {
  open: boolean;
  isExclusive: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}

const ExclusiveDialog: React.FC<ModalProps> = ({
  open,
  isExclusive = false,
  onClose,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="report-dialog-title"
      aria-describedby="report-dialog-description"
    >
      <DialogTitle id="report-dialog-title">
        {isExclusive
          ? `Remove Product as Exclusive`
          : `Make Product as Exclusive`}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          Are you sure you want to {isExclusive ? `remove` : `make`} this
          product as exclusive?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExclusiveDialog;
